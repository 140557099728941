import React, { useState, useRef } from 'react';
import useDragTask from '../hooks/usedragtask';
import { TAG } from '../hooks/usedroptask';
import { connect } from 'react-redux';
import { changeSelectedState, renameTag, setTagColorBg } from '../reducers/tags'
import { SliderPicker } from 'react-color';


const Tag = ({ tag, project, selectable, editable, filtered, touchstate, dispatch }) => {

	const _container = useRef(null)
	const _tag = useRef(null)



	const MODE_NORMAL = "mode/NORMAL"
	const MODE_EDIT_NAME = "mode/EDITNAME"
	const MODE_EDIT_COLOR = "mode/EDITCOLOR"
	
	const [getMode, setMode] = useState(MODE_NORMAL)






	const longclick = (ev) => {
			if (!editable || !touchstate.isEnabled || document.body.classList.contains('is-editingresourcecolor'))
				return

			document.body.classList.add('is-editingresourcecolor')
			setMode(MODE_EDIT_COLOR)
		}
	const startdrag = (ev) => {
			_container.current.classList.add('is-dragging')
			document.body.classList.add('drag-task')
			
			console.log("drag tag "+tag.id)
			ev.dataTransfer.setData("text/plain", "Tag " + tag.id)
			ev.dataTransfer.setData("id", tag.id)
			ev.dataTransfer.setData("prjid", project === undefined ? -1 : project.id)

			const crect = _container.current.getBoundingClientRect()
			const graboffsetx = ev.clientX - crect.x
			const trect = _tag.current.getBoundingClientRect()
			const padding = crect.width - trect.width
			ev.dataTransfer.setData("xoffset", graboffsetx)
			ev.dataTransfer.setData("xpadding", padding)
		}
	const enddrag = (ev) => {
			_container.current.classList.remove('is-dragging')
			document.body.classList.remove('drag-task')
			setTimeout(() => document.body.classList.remove('drag-task'), 20)
			console.log("dropped tag "+tag.id)
		}
	const click = (ev) => {
			if (selectable) {
				console.log("filter tag "+tag.id)
				dispatch(changeSelectedState(tag.id))
			}
		}
	const draggable = (ev) => {
			return touchstate.isEnabled
		}
	const dragtag = useDragTask( startdrag, enddrag, TAG, click, longclick, 500, undefined, undefined, [touchstate.isEnabled], draggable)



	const opacity = tag.currentSavingOps > 0
						? .75
						: 1

	const filteredClassName = (filtered && !tag.selected)
								? "is-filtered"
								: ""



	if (!editable)
		return (
			<div className="tag-container" style={{ opacity: opacity }} ref={_container}>
				<div ref={_tag}
					draggable
		 			className={'tag ' + filteredClassName}
					style={{ backgroundColor: tag.colorBg, color: tag.colorTxt }}
					{...dragtag}
					>{tag.label}</div></div>
		)
	

	// ========================================


	const [editLabel, setEditLabel] = useState(tag.label)
	const [editColor, setEditColor] = useState(tag.colorBg)

	const _input = useRef(null)


	const doubleclick = (ev) => {
			setMode(MODE_EDIT_NAME)
			setTimeout(() => {
				if (_input.current) {
					_input.current.focus()	// Hack to get _input to update with reference
				}
			}, 40)
		}
	const submitlabel = (ev) => {
			ev.preventDefault()
			if (!editLabel.trim()) {
				return
			}
			dispatch(renameTag(tag.id, editLabel))
			setMode(MODE_NORMAL)
		}
	const changelabel = (ev) => {
			setEditLabel(ev.target.value)
		}


	if (getMode === MODE_EDIT_NAME) {
		return (
			<form key={tag.id} onSubmit={submitlabel} className="no-button">
				<input ref={_input} className="tag margin-half-top"
					style={{backgroundColor: tag.colorBg, color: tag.colorTxt}}
					placeholder="Indtast mærke"
					type="text" maxLength="16"
					value={editLabel}
					onChange={changelabel} onBlur={submitlabel} />
			</form>
		)		
	}

	// ========================================


	const submitcolor = (ev) => {
			console.log("setting color to "+editColor)
			dispatch(setTagColorBg(tag.id, editColor))
			document.body.classList.remove('is-editingresourcecolor')
			setMode(MODE_NORMAL)
		}
	const changecolor = (color, ev) => {
			ev.stopPropagation()
			setEditColor(color.hex)
		}
	const change = (color, ev) => {
			ev.stopPropagation()
		}


	if (getMode === MODE_EDIT_COLOR) {
		const rect = _container.current.getBoundingClientRect()
		const top = _container.current.style.top
		const newtop = top === ""
						? Math.max(112, rect.top - 10) + 'px'
						: top
		return (
			<React.Fragment key={tag.id}>
				<div ref={_container} className="scroll-overflow-ui-container"
					style={{ top: newtop }}>
					<div className="ui-popover-container right-side"><SliderPicker 
						color={editColor}
						onChangeComplete={changecolor} onChange={change} /><button className="btn-small" onClick={submitcolor}>OK</button></div>
					<div className="tag invisible">{tag.label}</div>
				</div>
				<div className="tag-container"><div className="tag" style={{backgroundColor: editColor, color: tag.colorTxt}}>{tag.label}</div></div>
			</React.Fragment>
		)		
	}

	// ========================================


	return (
		<div className="tag-container" style={{ opacity: opacity }} ref={_container}>
			<div ref={_tag}
				draggable
	 			className={'tag ' + filteredClassName}
				style={{ backgroundColor: tag.colorBg, color: tag.colorTxt }}
				{...dragtag}
				onDoubleClick={doubleclick}
				>{tag.label}</div></div>
	);
	
}


const mapStateToProps = ({ touchstate }, { tag, project, selectable, editable, filtered }) => ({
	tag, 
	project, 
	selectable, 
	editable,
	filtered,
	touchstate,
})

export default connect(mapStateToProps)(Tag);