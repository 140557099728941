import { getWeekDomWidth, getBoardScrollDomEl, getBoardScroll, createWeekObjForWeekId, createWeekObjArr, getIdForDate, getDateOfISOWeek } from '../helpers/weeks'
import { createWarnConflict } from '../helpers/warnConflict'

export const ADD_BEFORE = 'week/ADD_BEFORE'
export const ADD_AFTER = 'week/ADD_AFTER'

export const ADD_WARN_CONFLICT_ID = 'weekwarnconflicts/ADD_WARN_CONFLICT_ID'
export const ADD_MULTIPLE_WARN_CONFLICTS = 'weekwarnconflicts/ADD_MULTIPLE_WARN_CONFLICTS'
export const REMOVE_WARN_CONFLICT_ID = 'weekwarnconflicts/REMOVE_WARN_CONFLICT_ID'
export const ADD_CONFLICT_PROJECTWARNING = 'weekwarnconflicts/ADD_CONFLICT_PROJECTWARNING'
export const REMOVE_CONFLICT_PROJECTWARNING = 'weekwarnconflicts/REMOVE_CONFLICT_PROJECTWARNING'


const initWeekRange = {
  first: getIdForDate( new Date().setDate(new Date().getDate() - (3 * 7) ) ),	// 3 weeks back
  last: getIdForDate( new Date().setDate(new Date().getDate() + 52 * 7 ) )	// 12 months forward
}

const initialState = {
  first: initWeekRange.first,
  last: initWeekRange.last,
  weeks: createWeekObjArr(initWeekRange.first, initWeekRange.last)
}




export function stateWithUpdatedWeek(state, weekid, updateCallback) {
  return  {
      ...state,
      weeks: state.weeks.map(week => {
        if ((typeof weekid === 'string' && week.id !== weekid)  // provided with week id
            || (Array.isArray( weekid ) && weekid.indexOf(week.id) === -1)) {  // or with array of week ids
            return week
        }
        
        return Object.assign({}, week, updateCallback(week))
      })
    }
}



export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BEFORE:
      const oldFirst = createWeekObjForWeekId(state.first)
      const oldFirstDate = getDateOfISOWeek(oldFirst.number, oldFirst.year)
      const newFirst = getIdForDate( new Date(oldFirstDate).setDate(oldFirstDate.getDate() - ((action.weeks || 3) * 7) ) )
      const newFirstEnd = getIdForDate( new Date(oldFirstDate).setDate(oldFirstDate.getDate() - 7 ) )
      return {
        ...state,
        first: newFirst,
        weeks: createWeekObjArr(newFirst, newFirstEnd).concat(...state.weeks),
      }

    case ADD_AFTER:
      return {
        ...state,
        last: state.last + (action.weeks || 3),
        weeks: state.weeks.concat(createWeekObjArr(state.last + 1, state.last + action.weeks)),
      }


    case ADD_MULTIPLE_WARN_CONFLICTS:
      return stateWithUpdatedWeek(state, action.weekids, (week) => {
        action.weeks[week.id].forEach(actionWarnConflict => {
          const index = week.warnConflicts.findIndex(t => t.resourceid === actionWarnConflict.resourceid)
          
          if (index === -1) {
            week.warnConflicts.push(createWarnConflict(actionWarnConflict.resourceid, actionWarnConflict.taskids))
          } else {
            const warnConflict = week.warnConflicts[index]
            const taskids = actionWarnConflict.taskids
                                      .concat(warnConflict.taskids)
                                      .filter((taskid, index, self) => taskid !== undefined && self.indexOf(taskid) === index)
            warnConflict.taskids = taskids
          }
        })
        
        return {
              warnConflicts: week.warnConflicts
            }
      })

    case ADD_WARN_CONFLICT_ID:
      return stateWithUpdatedWeek(state, action.weekids, (week) => {
        const index = week.warnConflicts.findIndex(t => t.resourceid === action.resourceid)
      
        if (index === -1) {
          week.warnConflicts.push(createWarnConflict(action.resourceid, action.taskid))
        } else {
          const warnConflict = week.warnConflicts[index]
          const taskIndex = warnConflict.taskids.indexOf(action.taskid)
          
          if (taskIndex === -1)
          warnConflict.taskids.push(action.taskid)
        }
      
        return {
              warnConflicts: week.warnConflicts
            }
      })
    
    case REMOVE_WARN_CONFLICT_ID:
      return stateWithUpdatedWeek(state, action.weekids, (week) => {
        const index = week.warnConflicts.findIndex(t => t.resourceid === action.resourceid)
      
        if (index === -1)
        return week
      
        const warnConflict = week.warnConflicts[index]
        const taskIndex = warnConflict.taskids.indexOf(action.taskid)
        
        if (taskIndex !== -1)
        warnConflict.taskids.splice(taskIndex, 1)
      
        return {
              warnConflicts: week.warnConflicts
            }
    })


    case ADD_CONFLICT_PROJECTWARNING:
      return stateWithUpdatedWeek(state, action.weekids, (week) => {
        const index = week.projectsWithConflicts.findIndex(t => t.projectid === action.projectid)
      
        if (index === -1) {
          week.projectsWithConflicts.push(createWarnConflict(action.projectid, action.taskid))
        } else {
          const warnConflict = week.projectsWithConflicts[index]
          const taskIndex = warnConflict.taskids.indexOf(action.taskid)
          
          if (taskIndex === -1)
          warnConflict.taskids.push(action.taskid)
        }
      
        return {
              projectsWithConflicts: week.projectsWithConflicts
            }
      })

    case REMOVE_CONFLICT_PROJECTWARNING:
      return stateWithUpdatedWeek(state, action.weekids, (week) => {
        const index = week.projectsWithConflicts.findIndex(t => t.projectid === action.projectid)
      
        if (index === -1)
        return week
      
        const warnConflict = week.projectsWithConflicts[index]
        const taskIndex = warnConflict.taskids.indexOf(action.taskid)
        
        if (taskIndex !== -1)
        warnConflict.taskids.splice(taskIndex, 1)
      
        return {
              projectsWithConflicts: week.projectsWithConflicts
            }
    })




    default:
      return state
  }
}

let lastAddWeeks = null

export const addWeekBefore = (weeks) => {
  // simple throttle to avoid too many adds
  if (lastAddWeeks && (new Date() - lastAddWeeks < 150)) return
  
  lastAddWeeks = new Date()
  
  const weekswidth = getWeekDomWidth()
  const scrollEl = getBoardScrollDomEl()
  const oldScroll = getBoardScroll()
  
  // Hack to avoid immediate scrolling, keeping the current scroll position, and avoid loading more weeks 
  scrollEl.scrollTo(oldScroll.scrollX + (weekswidth * weeks), oldScroll.scrollY)
  setTimeout(() => {
    scrollEl.scrollTo(oldScroll.scrollX + (weekswidth * weeks), oldScroll.scrollY)
  }, 0)
  /*setTimeout(() => {
    scrollEl.scrollTo(oldScroll.scrollX + (weekswidth * weeks), oldScroll.scrollY)
  }, 250)
  /*setTimeout(() => {
    scrollEl.scrollTo(oldScroll.scrollX + (weekswidth * weeks), oldScroll.scrollY)
  }, 500)*/

  return dispatch => {
    dispatch({
      type: ADD_BEFORE,
      weeks: weeks
    })
  }
}

export const addWeekAfter = (weeks) => {
  return dispatch => {
    dispatch({
      type: ADD_AFTER,
      weeks: weeks
    })
  }
}
