import React, { useState, useRef } from 'react'
import useDragTask from '../hooks/usedragtask'
import useDropTask from '../hooks/usedroptask'
import useExtendTask from '../hooks/useextendtask'

import { TASKGROUP, TASK, PROJECT } from '../hooks/usedroptask'
import { connect } from 'react-redux'
import { setTaskStart, setTaskDurationTo } from '../reducers/tasks'
import { createWeekObjForWeekAndYear, calcWeeksDuration, calcWeekObjForWeekDuration, getIdForWeekObj } from '../helpers/weeks'
import { offsetWarnConflictForTask } from '../helpers/warnConflict'


const Task = ({ task, taskgroup, project, weeks, showaslist, dispatch }) => {

	const _container = useRef(null)
	const _task = useRef(null)
	const _conflicts = useRef(null)

	const [unitwidth, setUnitwidth] = useState(0)
	const [dragStartClientX, setDragStartClientX] = useState(0)
	const [dragOffsetX, setDragOffsetX] = useState(0)

	const startdrag = (ev) => {
			_container.current.classList.add('is-dragging')
			document.body.classList.add('drag-task')
			
			console.log("drag task "+task.id)
			ev.dataTransfer.setData("text/plain", "Task " + task.id)
			ev.dataTransfer.setData("id", task.id)
			const source = taskgroup
							? TASKGROUP
							: PROJECT
			ev.dataTransfer.setData("source", source)
			ev.dataTransfer.setData("prjid", project.id)
			
			if (source === TASKGROUP) {
				ev.dataTransfer.setData("tskgid", taskgroup.id)
			}

			const crect = _container.current.getBoundingClientRect()
			const graboffsetx = ev.clientX - crect.x
			setDragOffsetX(graboffsetx)
			
			const trect = _task.current.getBoundingClientRect()
			const padding = crect.width - trect.width
			ev.dataTransfer.setData("xoffset", graboffsetx)
			ev.dataTransfer.setData("xpadding", padding)
			
			setDragStartClientX(ev.clientX)
			const uwidth = Math.round(_container.current.clientWidth / task.duration)
			setUnitwidth(uwidth)
		}
	const enddrag = (ev) => {
			_container.current.classList.remove('is-dragging')
			document.body.classList.remove('drag-task')
			/*
			if (_task.current === ev.currentTarget) {
			}*/
			setTimeout(() => document.body.classList.remove('drag-task'), 20)
			console.log("dropped task "+task.id)
		}
	const dragtask = useDragTask( startdrag, enddrag, TASK, undefined, undefined, 500)


	const onDrop = (ev) => {
		// Dropped onto itself, since we ignore dropping onto other tasks with css ignore pointer-events
		if (ev.currentTarget !== _task.current)
			return
		
		const offsetStart = Math.floor((ev.clientX - dragStartClientX + dragOffsetX) / unitwidth)
		if (offsetStart !== 0) {
			const newWeek = calcWeekObjForWeekDuration(task.startweek, task.startyear, offsetStart)

			// Has to be set before setting new task start and duration, as it relies on previous (current) value
			if (project.warnConflicts) {
				dispatch(offsetWarnConflictForTask(project.id, task.id, newWeek.number, newWeek.year, task.duration))
			}

			dispatch(setTaskStart(task.id, newWeek.number, newWeek.year))
		}
		
		_container.current.classList.remove('is-dragging')
		document.body.classList.remove('drag-task')
		setTimeout(() => document.body.classList.remove('drag-task'), 20)
	}

	const droptask = useDropTask(undefined, undefined, onDrop, [TASK], undefined)


	const [taskStartWidthPx, setTaskStartWidthPx] = useState(0)
	
	const startExtend = () => {
			if (_container.current === null) return
			
			_container.current.classList.add('is-extending')
			const width = _container.current.clientWidth
			const uwidth = Math.round(width / task.duration)

			setTaskStartWidthPx(width)
			setUnitwidth(uwidth)
		}
	const stopExtend = () => {
			//console.log("Stop extend")
			if (_container.current === null) return

			_container.current.classList.remove('is-extending')
			
			let marginleft = parseInt(_container.current.style.marginLeft)
			if (isNaN(marginleft)) marginleft = 0	// marginleft has not been set
			
			const offsetStart = Math.floor( marginleft / unitwidth )
			const newWeek = calcWeekObjForWeekDuration(task.startweek, task.startyear, offsetStart)

			const width = parseInt( _container.current.style.width ) - (offsetStart * unitwidth - marginleft)
			const newDuration = Math.max(1, Math.round( width / unitwidth ))

			if (newDuration === task.duration && newWeek.number === task.startweek && newWeek.year === task.startyear) {
				// same as old one... no idea in hitting db
				setStyles(_container, 0, newDuration * unitwidth) // But update width
				_conflicts.current.style.marginLeft = ''
				return	
			}
			
			// Has to be set before setting new task start and duration, as it relies on previous (current) value
			if (project.warnConflicts) {
				dispatch(offsetWarnConflictForTask(project.id, task.id, newWeek.number, newWeek.year, newDuration))
			}

			dispatch(setTaskStart(task.id, newWeek.number, newWeek.year))
			
			dispatch(setTaskDurationTo(task.id, newDuration))
			
			// reset styles for cases where start and duration has not changed and triggerede a redraw
			setStyles(_container, 0, newDuration * unitwidth)
			_conflicts.current.style.marginLeft = ''
		}
	
	const setStyles = (container, left, width) => {
			if (container.current === null) return
			
			container.current.style.marginLeft = left + "px"
			container.current.style.width = width + "px"
		}
	const setExtendWidth = (container, pxoffset, widthoffset) => {
			setStyles(container, 
				Math.min(pxoffset, taskStartWidthPx - unitwidth),
				Math.max(unitwidth, taskStartWidthPx + widthoffset))
		}
	const extendLeft = (ev, pos) => {
			if (_container.current === null) return

			setExtendWidth(_container, pos.x, -pos.x)
			
			if (_conflicts.current === null) return
			
			_conflicts.current.style.marginLeft = Math.max(-pos.x, (task.duration - 1) * -unitwidth) + "px"
		}
	const extendRight = (ev, pos) => {
			if (_container.current === null) return

			setExtendWidth(_container, 0, pos.x)
		}



	const extendtaskleft = useExtendTask( startExtend, stopExtend, extendLeft, task, true, true )
	const extendtaskright = useExtendTask( startExtend, stopExtend, extendRight, task, true, true )



	const unitWidthRem = 6
	const calcWidth = (units) => {
			return unitWidthRem * units
		}
	const taskWidthRem = calcWidth(task.duration)

	const marginAbove = showaslist === "true" ? 1.75 : .5
	const marginBetween = .25
	const padding = 0
	const lineHeight = 1.75

	const width = taskWidthRem + 'rem'
	const startWeekObj = createWeekObjForWeekAndYear(task.startweek, task.startyear)
	const left = calcWidth( calcWeeksDuration(startWeekObj, weeks.first) ) + 'rem'
	const unitheight = showaslist === "true" || project.isCompacted
						? .25
						: lineHeight + padding
	const top = (marginAbove + (task.y * (unitheight + marginBetween))) + 'rem'

	const label = showaslist === "true" || project.isCompacted
						? ''
						: task.resource.name

	const opacity = task.currentSavingOps > 0
						? .75
						: 1

	const startWeekId = getIdForWeekObj(startWeekObj)
	const innerMargin = 2 * marginBetween
	// Don't calculate end week id, as stripes are positioned with "right" and the cut with "overflow: hidden" to make seamless seams
	return (
		<div className="task-container" style={{ width: width, top: top, left: left, opacity: opacity }} ref={_container}>
			<div ref={_task} 
				draggable
				className="task"
				style={{ backgroundColor: task.resource.colorBg, color: task.resource.colorTxt, height: unitheight + 'rem' }}
				{...dragtask} {...droptask}
				><span className="extend" {...extendtaskleft}></span><span className="conflicts" ref={_conflicts}>{
					task.conflicts.map((weekid, index) =>
							<span key={weekid} className="conflict" style={{ width: (weekid === startWeekId ? -innerMargin : 0) + unitWidthRem + 'rem', left: (weekid === startWeekId ? 0 : -innerMargin) + calcWidth( - calcWeeksDuration(startWeekObj, weekid) ) + 'rem' }}></span>
						)
				}</span><span className="label">{label}</span><span className="extend" {...extendtaskright}></span></div></div>
	);
}



export default connect()(Task);