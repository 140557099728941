import { GET_ALL_REQUESTED, GET_ALL, ADD_REQUESTED, ADD, DELETE_REQUESTED, UPDATE_REQUESTED, addWarnConflictsAllProjects, shelfTaskgroupsForAllProjects } from '../reducers/projects';
import { apiAction, getUrl } from '../actions/api';
import { METHOD_POST, METHOD_DELETE, METHOD_PUT } from "../middleware/api";




export const fetchProjectsAPI = () => {
	return apiAction({
		url: getUrl("projects"),
		onSuccess: setProjects,
		onFailure: () => console.log("Error while fetching projects."),
		label: GET_ALL_REQUESTED
	})
}

export const setProjects = (data) => {
	return (dispatch, getState) => {
		dispatch({
			type: GET_ALL,
			payload: data
		})
		
		dispatch(addWarnConflictsAllProjects())
		dispatch(shelfTaskgroupsForAllProjects())
	}
}



export const postProjectAPI = (data) => {
	return apiAction({
		url: getUrl("projects"),
		method: METHOD_POST,
		data: data,
		onSuccess: setProject,
		onFailure: () => console.log("Error while posting project."),
		label: ADD_REQUESTED
	})
}

export const setProject = (data) => {
	return {
		type: ADD,
		payload: data
	}
}


export const deleteProjectAPI = (data) => {
	return apiAction({
		url: getUrl("projects", data.id),
		method: METHOD_DELETE,
		data: data,
		onFailure: () => console.log("Error while putting project."),
		label: DELETE_REQUESTED
	})
}



export const updateProjectAPI = (data) => {
	return apiAction({
		url: getUrl("projects", data.id),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while updating project."),
		label: UPDATE_REQUESTED
	})
}





export const updateProjectSortAPI = (data) => {
	return apiAction({
		url: getUrl("projects", "updatesort"),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while updating project ids."),
		label: UPDATE_REQUESTED
	})
}


export const removeTaskgroupsFromProjectsAPI = (data) => {
	return apiAction({
		url: getUrl("projects", "removetaskgroups"),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while removing taskgroups from all projects.")
	})
}



export const removeTasksFromProjectsAPI = (data) => {
	return apiAction({
		url: getUrl("projects", "removetasks"),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while removing tasks from all projects.")
	})
}



export const removeTagsFromProjectsAPI = (data) => {
	return apiAction({
		url: getUrl("projects", "removetags"),
		method: METHOD_PUT,
		data: data,
		onFailure: () => console.log("Error while removing tags from all projects.")
	})
}

