import React from 'react';
import { connect } from 'react-redux';
import { addProject } from '../reducers/projects';

const ProjectAddButtonRow = ({ showcontent, weeks, projects, dispatch }) => {
	let input

	const marginAboveProject = .5
	const marginAbove = .5
	const marginBelow = .5
	const marginBetween = .25
	const padding = 0
	const lineHeight = 1.75

	let totalheight = 0
	projects.forEach((project) => {
		const pheight = Math.max(3.75, (marginAbove + ((project.maxy + 1) * (lineHeight + marginBetween + padding) - marginBetween) + marginBelow))
		totalheight += marginAboveProject + pheight
	})
	
	const headerHeight = 7.55
	const height = 'calc(100vh - ' + (headerHeight + totalheight) + 'rem)'
	
	const submit = (ev) => {
			ev.preventDefault();
			if (!input.value.trim()) {
				return
			}
			dispatch(addProject(input.value))
			input.value = ''
		}
	
	return !showcontent
		? (
				<tr className="spacer">
					<th style={{ height: height }}>
						<form className="padding-bottom hide-in-presentation" 
							onSubmit={submit}>
							<input ref={node => (input = node)} className="inline margin-top" type="text" placeholder="Indtast projektnavn" />
							<button type="submit" className="inline margin-top margin-half-left">+</button>
						</form>
					</th>
				</tr>
			)
		: (
				<tr className="spacer">
					<td className="bg-container" style={{ height: height }}>
					{ weeks.weeks.map((week, index) => {
						return <div key={week.id} className={"empty-bg " + (week.beforenow ? "before-week" : "") + " " + (week.now ? "this-week" : "") }></div>
					} ) }
					</td>
				</tr>
			);
}


const mapStateToProps = ({ weeks }, { projects, showcontent }) => ({
	weeks,
	projects,
	showcontent
})

export default connect(mapStateToProps)(ProjectAddButtonRow);