import React, { useState } from 'react'
import { connect } from 'react-redux'
import ProjectTaskgroup from '../components/project-taskgroup'


const ProjectTaskgroupShelf = ({ project }) => {
	if (!project.taskgroupshelf || project.taskgroupshelf.length === 0) {
		return (
			<div></div>
		)
	}
	
	
	const MODE_FOLDED = "mode/MODE_FOLDED"
	const MODE_UNFOLDED = "mode/MODE_UNFOLDED"
	
	const [getMode, setMode] = useState(MODE_FOLDED)
	
	const onclick = (ev) => {
		setMode(getMode === MODE_FOLDED ? MODE_UNFOLDED : MODE_FOLDED)
	}
	
	
	return (
		<React.Fragment key={"shelf" + project.id}>
			<button className={ getMode === MODE_FOLDED ? "folded taskgroup-shelf" : "unfolded taskgroup-shelf" } onClick={ onclick }><i className="icon"></i> { project.taskgroupshelf.length } ikke planlagt{ project.taskgroupshelf.length > 1 ? "e" : "" }</button>
			<div className="project-taskgroup-shelf">
			{ project.taskgroupshelf.map((taskgroup, index) =>
				<ProjectTaskgroup key={"shelf" + taskgroup.id} project={project} taskgroup={taskgroup} showaslist="true" />
			) }
			</div>
		</React.Fragment>
	)
}

export default connect()(ProjectTaskgroupShelf)
