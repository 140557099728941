import React from 'react';
import { connect } from 'react-redux';
import { toggleTouchstate } from '../reducers/touchstate';

const TouchstateToggle = ({ dispatch }) => {
	const toggleTouchClick = (ev) => {
			ev.preventDefault()
			ev.stopPropagation()
			dispatch(toggleTouchstate())
		}
	
	return (
		<button id="touch-toggle" className="no-ui" onClick={toggleTouchClick}>Disable editing</button>
	)
}


export default connect()(TouchstateToggle)