import React, { useEffect, useState, useRef, memo } from 'react'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { selectProjects } from '../../reducers/projects'
import { selectSelectedTags } from '../../reducers/tags'
import { selectTasksWithinWeeks } from '../../reducers/tasks'
import { selectResources, selectSelectedResources } from '../../reducers/resources'
import { setTaskgroupLatestStart } from '../../reducers/taskgroups'
import { addWeekBefore } from '../../reducers/weeks'
import { dispatchViewboxRelatedEvents, dispatchCancelViewboxRelatedEvents } from '../../reducers/viewbox'
import useMouseKeyTimeout from '../../hooks/usemousekeytimeout'
import useExtendTask from '../../hooks/useextendtask'
import WeekNumbers from '../../components/week-numbers'
import ProjectList from '../../components/project-list'
import ProjectAddButtonRow from '../../components/project-addbutton-row'
import ResourceList from '../../components/resource-list'
import TagList from '../../components/tag-list'
import TagMenuButton from '../../components/tag-menubutton'
import FilterState from '../../components/filter-state'
import TouchstateToggle from '../../components/touchstate-toggle'
import { fetchResourcesAPI } from "../../actions/api-resources"
import { fetchProjectsAPI } from "../../actions/api-projects"
import { fetchTagsAPI } from "../../actions/api-tags"
import { fetchTasksAPI } from "../../actions/api-tasks"
import { fetchTaskgroupsAPI } from "../../actions/api-taskgroups"

import { isTouchDevice /*, isIOS /*, isAndroid /*, isChrome */ } from "../../helpers/useragent"
import { createWeekObjForWeekId, getWeekDomWidth, getBoardScroll } from "../../helpers/weeks"
import { mergeWeeksWithTaskConflicts } from "../../helpers/warnConflict"


let throttleTimer = {}
const throttle = (callback, delay, id) => {
	clearTimeout(throttleTimer[id])
	throttleTimer[id] = setTimeout(callback, delay || 1000)
}


const Home = ({ weeks, projects, filter, tags, resources, touchstate, outOfSyncTaskgroups, fetchTasksAPI, fetchTaskgroupsAPI, fetchTagsAPI, fetchResourcesAPI, fetchProjectsAPI, dispatchViewboxRelatedEvents, /*dispatchCancelViewboxRelatedEvents,*/ addWeekBefore, dispatch }) => {
	
	useEffect(() => {
		console.log("fetching projects, tasks and resources")
		fetchTasksAPI()
		fetchTaskgroupsAPI()
		fetchTagsAPI()
		fetchResourcesAPI()
		
		// TODO: Fetch projects after tasks and resources using promise or load all together, instead of waiting 1 second. Tasks and resources has to be loaded before projects.
		setTimeout(() => {
			fetchProjectsAPI()
			setTimeout(() => { dispatchViewboxRelatedEvents(true) }, 1000)
		}, 1000)
	}, [])

	// Temporary hack for fixing out of sync taskgroups until the bug is fixed
	// The out of sync taskgroups are found when selecting the projects doing the masonry... can't call dispatch from there, so for now it is done in here
	if (outOfSyncTaskgroups.length > 0) {
		outOfSyncTaskgroups.forEach(tg => {
			dispatch(setTaskgroupLatestStart(tg.id, tg.lateststartweek, tg.lateststartyear))
		})
	}




	const VIEWSTATE_NORMAL = "viewstate/NORMAL"
	const VIEWSTATE_PRESENTATION = "viewstate/PRESENTATION"
	const [viewState, setViewState] = useState(VIEWSTATE_NORMAL)

	const onTimeoutViewstate = () => setViewState(VIEWSTATE_PRESENTATION)
	const onFirstTimeoutResetViewstate = () => {
				console.log("reset view state")
				setViewState(VIEWSTATE_NORMAL)
			}
	useMouseKeyTimeout( onTimeoutViewstate, onFirstTimeoutResetViewstate, 5 )
	

	const _headerLogo = useRef(null)
	const _headerProjects = useRef(null)
	const _headerWeeks = useRef(null)
	const _contents = useRef(null)

	const _androidTouchScrollElement = useRef(null)
	
	const enableJsDragUpdateOfBoard = !isTouchDevice
	
	//const _touchScrollElement = document.querySelector("main")
	//if (!_touchScrollElement) throw Error("Touch scroll element container for board not found")
		
	/*const transformScrollTo = (x, y) => {
		_headerWeeks.current.style.transform = "translate3d(" + x + "px, 0px, 0px)"
		_headerProjects.current.style.transform = "translate3d(0px, " + y + "px, 0px)"
		_contents.current.style.transform = "translate3d(" + x + "px, " + y + "px, 0px)"
	}

	const getScrollOffset = (dragOffset) => {
		return {
			x: Math.min(scrollStartCoords.x, dragOffset.x),
			y: Math.min(scrollStartCoords.y, Math.max(scrollStartCoords.y - (documentHeight() - window.innerHeight), dragOffset.y))
		}
	}
	
	const documentHeight = () => {
		const body = document.body
		const html = document.documentElement

		return Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight )
	}*/

	const [scrollStartCoords, setScrollStartCoords] = useState({ x: 0, y: 0 })
	const [weekDomWidth, setWeekDomWidth] = useState(null)

	const scrollStart = (ev) => {
			if (enableJsDragUpdateOfBoard) {
				/*const transform = get2dTransform('#content-projects')
				setScrollStartCoords({ x: transform.x, y: transform.y })*/
				setScrollStartCoords({ x: window.scrollX, y: window.scrollY })
			}
			dispatchCancelViewboxRelatedEvents()
		}
	const scrollMove = (ev, offset) => {
			if (enableJsDragUpdateOfBoard) {
				window.scrollTo(scrollStartCoords.x - offset.x, scrollStartCoords.y - offset.y)
				//const scroll = getScrollOffset(offset)
				//transformScrollTo(scroll.x, scroll.y)
			}
		}
	const scrollEndThrottled = (ev, offset) => {
			/*if (isIOS) {
				const scroll = getScrollOffset(offset)
				window.scrollTo(window.scrollX - scroll.x, window.scrollY - scroll.y)
	
				transformScrollTo(0, 0)
			}*/
			
			const scroll = getBoardScroll(_androidTouchScrollElement)
			if (weekDomWidth === null) {
				setWeekDomWidth(getWeekDomWidth())
			}

			if (scroll.scrollX < weekDomWidth) {
				addWeekBefore(10)
			}
			
			//console.log("scroll "+scroll.scrollX+ ", w.scrollX: " + window.scrollX + ", week width: "+ weekWidth + ", jsUpdate: " + enableJsDragUpdateOfBoard)
			dispatchViewboxRelatedEvents()
		}
	const scrollEnd = (ev, offset) => {
		throttle(() => {
			scrollEndThrottled(ev, offset)
		}, 550, 'scrollend')
	}

	const dragScroll = useExtendTask( scrollStart, scrollEnd, scrollMove, scrollStartCoords, false, true )

	

	useEffect(() => {
		let timerid = null
		
		const onScroll = (ev) => {
			clearTimeout(timerid)
			timerid = setTimeout(scrollEnd, 300)
		}
	
		window.addEventListener('scroll', onScroll)

		return () => {
			window.removeEventListener('scroll', onScroll)
		}
	})
	

	const unitWidthRem = 6;
	const weekswidth = (unitWidthRem * weeks.weeks.length + 0.25) + 'rem'

	return (
		<div ref={_androidTouchScrollElement} id="content" className={(viewState === VIEWSTATE_PRESENTATION ? "presentation-state" : "normal-state") + " " + (touchstate.isEnabled ? "touch-enabled" : "touch-disabled")}>
			<table id="tablemain">
				<thead>
					<tr>
						<th id="header-logo" ref={_headerLogo}>
						    <header>
								<Link to="/" className="logo"><h2>Teknologisk</h2> Sprints</Link>
								<TagMenuButton tags={tags} />
						    </header>
							<FilterState filter={filter} />
						</th>
						<th id="header-weeks" ref={_headerWeeks} style={{ minWidth: weekswidth }}><WeekNumbers weeks={weeks} /></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th id="header-projects" ref={_headerProjects}><table>
							<thead>
								<tr className="spacer"><th className="bg-container"></th></tr>
								<ProjectList showcontent={false} projects={projects} filter={filter} />
								<ProjectAddButtonRow showcontent={false} projects={projects} />
							</thead>
						</table></th>
						<td id="content-projects" ref={_contents} {...dragScroll}><table>
							<tbody>
								<tr className="spacer"><td className="bg-container">{
									weeks.weeks.map((week, index) => {
										return <div key={week.id} className={"empty-bg " + (week.beforenow ? "before-week" : "") + " " + (week.now ? "this-week" : "") }></div>
									} ) }</td></tr>
								
								<ProjectList showcontent={true} projects={projects} filter={filter} />
								<ProjectAddButtonRow showcontent={true} projects={projects} />
							</tbody>
						</table></td>
					</tr>
				</tbody>
			</table>
			<TagList showing={tags.showingTagsMenu} tags={tags.tags} filtered={filter.isTagsFiltered} next={tags.latestid} />
			<ResourceList resources={resources.resources} next={resources.latestid} />
			<TouchstateToggle />
		</div>
	)
}

const mapStateToProps = (/*{ projects, resources, weeks, taskgroups, tasks, tags, touchstate }*/) => {
		const getFilteredTags = createSelector([(state) => state.tags.tags], selectSelectedTags)
		const getFilteredResources = createSelector([(state) => state.resources.resources], selectSelectedResources)
	
		const getFilter = createSelector([
			(state) => state.tags.tags,
			getFilteredTags,
			(state) => state.resources.resources,
			getFilteredResources,
			(state) => state.weeks
		], (tags, filteredTags, resources, filteredResources, weeks) => {
			return {
					tags: filteredTags,
					resources: filteredResources,
					weeks: {...weeks},
					isTagsFiltered: filteredTags.length > 0 && filteredTags.length !== tags.length,
					isResourcesFiltered: filteredResources.length > 0 && filteredResources.length !== resources.length
				}
		})
		
		const getAllVisibleTasks = createSelector([
			(state) => state.tasks.tasks,
			(state) => undefined,	// task ids to find in state.tasks... if undefined filter from all tasks
			(state) => state.taskgroups.taskgroups,
			(state) => createWeekObjForWeekId(state.weeks.first),
			(state) => createWeekObjForWeekId(state.weeks.last)
		], selectTasksWithinWeeks)
		
		const getSelectedProjects = createSelector([
			(state) => state.projects, 
			(state) => state.taskgroups.taskgroups, 
			getAllVisibleTasks,
			(state) => state.tags.tags, 
			(state) => state.resources.resources, 
			getFilter
		], selectProjects)
		
		const getSelectedResources = createSelector([
			(state) => state.resources
		], selectResources)
		
		const getWeeksWithConflicts = createSelector([
			(state) => state.weeks,
			getAllVisibleTasks,
			getSelectedProjects
		], (weeks, tasks, selectedProjects) => {
			return mergeWeeksWithTaskConflicts(weeks, tasks, selectedProjects.sorted)
		})
		
		return (state, ownProps) => {
			const selectedProjects = getSelectedProjects(state)
			return {
				weeks: getWeeksWithConflicts(state),
				projects: selectedProjects.sorted,
				resources: getSelectedResources(state),
				taskgroups: state.taskgroups.taskgroups,
				tasks: getAllVisibleTasks(state),
				tags: state.tags,
				filter: getFilter(state),
				touchstate: state.touchstate,
				outOfSyncTaskgroups: selectedProjects.outOfSyncTaskgroups,
			}
		}
	}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			changePage: () => push('/guide'),
			fetchResourcesAPI,
			fetchTasksAPI,
			fetchTaskgroupsAPI,
			fetchTagsAPI,
			fetchProjectsAPI,
			dispatchViewboxRelatedEvents,
			//dispatchCancelViewboxRelatedEvents,
			addWeekBefore,
			dispatch,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(Home))
