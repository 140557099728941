import { useState, useEffect, useCallback } from 'react'

export default function useLongPress(longPressCallback = () => {}, longPressMs = 300, dragStartCallback = () => {}, clickCallback = () => {}, doubleClickCallback = () => {}, doubleClickMs = 300) {
	const START_LONGPRESS = 'longpress/START_LONGPRESS'
	const STOP = 'longpress/STOP'
	
	const [startSingleClickTime, setStartSingleClickTime] = useState(STOP)
	const [startDoubleClickTime, setStartDoubleClickTime] = useState(new Date())
	
	useEffect(() => {
		let longtimerId
		let singletimerId
		
		switch (startSingleClickTime) {
			case START_LONGPRESS:
				longtimerId = setTimeout(() => {
					clearTimeout(singletimerId)
					//setExecutedLongPress(true)
					//console.log("Long press")
					longPressCallback()
					setStartSingleClickTime(STOP)
				}, longPressMs)
				break
			
			case STOP:
				break
			
			default:
				const msSinceLastSingleClick = startSingleClickTime - startDoubleClickTime
				
				if (msSinceLastSingleClick > 16 && msSinceLastSingleClick < doubleClickMs) {
					//console.log("Double click")
					doubleClickCallback()
				} else {
					setStartDoubleClickTime(startSingleClickTime)
					singletimerId = setTimeout(() => {
						//console.log("Click")
						clickCallback()
					}, doubleClickMs + 20)
				}
				break
		}

		
		return () => {
			clearTimeout(singletimerId)
			clearTimeout(longtimerId)
		};
	}, [startSingleClickTime])
	
	
	const start = useCallback((ev) => {
		ev.stopPropagation()
		setStartSingleClickTime(START_LONGPRESS)
	}, [])
	const stop = useCallback(() => {
		setStartSingleClickTime(new Date())
	}, [])
	const out = useCallback(() => {
		setStartSingleClickTime(STOP)
	}, [])
	
	const dragStart = useCallback((ev) => {
		dragStartCallback(ev)
		setStartSingleClickTime(STOP)
	}, [])
	
	return {
		onMouseDown: start,
		onMouseUp: stop,
		onMouseLeave: out,
		onTouchStart: start,
		onTouchEnd: stop,
		onDragStart: dragStart,
	}
}