import { API_START, API_END, API_ERROR } from '../actions/api'
import { postTaskAPI, deleteTaskAPI, deleteTasksAPI, updateTaskAPI, deleteTasksByResourceAPI } from '../actions/api-tasks'
import { removeTasksFromAllProjects } from '../reducers/projects'
import { selectTaskgroupWithTask, removeTaskFromTaskgroup } from '../reducers/taskgroups'
import { createWeekObjForWeekAndYear, createWeekObjForWeekId, calcWeekObjForWeekDuration, compareWeeks, getIdForDate, iterateWeeks } from '../helpers/weeks'
import { removeWarnConflictForTask } from '../helpers/warnConflict'

export const GET_ALL_REQUESTED = 'task/GET_ALL_REQUESTED'
export const GET_ALL = 'task/GET_ALL'

export const ADD_REQUESTED = 'task/ADD_REQUESTED'
export const ADD = 'task/ADD'

export const DELETE_REQUESTED = 'task/DELETE_REQUESTED'

export const DELETE = 'task/DELETE'
export const DELETE_MULTIPLE = 'task/DELETE_MULTIPLE'
export const DELETE_BY_RESOURCE = 'task/DELETE_BY_RESOURCE'

export const UPDATE_REQUESTED = 'task/UPDATE_REQUESTED'

export const SET_START = 'task/SET_START'
export const OFFSET_START = 'task/OFFSET_START'
export const SET_DURATION = 'task/SET_DURATION'


export const createTask = (id, resourceid, startweek, startyear, duration) => {
		return {
				id: id,
				resource: resourceid,
				startweek: startweek,
				startyear: startyear,
				duration: duration,
				y: 0,
				currentSavingOps: 0
			}
	}



const initialState = {
	count: 0,
	tasks: [],
	isDeleting: false,
}

export function newCID(resource) {
	const name = resource.name || "_anon";
	const d = new Date();
	const dts = d.getFullYear() + ("0"+(d.getMonth()+1)).slice(-2) + ("0" + d.getDate()).slice(-2) + ("0" + d.getHours()).slice(-2) + ("0" + d.getMinutes()).slice(-2) + ("0" + d.getSeconds()).slice(-2) + ("0000" + Math.round(Math.random() * 1000)).slice(-4);
	return name + "-" + dts;
}



function stateWithUpdatedTask(state, id, updateCallback) {
	return  {
			...state,
			tasks: state.tasks.map(task => {
				if (task.id !== id) {
				    return task
				}
				
				return Object.assign({}, task, updateCallback(task))
			})
		}
}




export default (state = initialState, action) => {
	switch (action.type) {

		case API_START:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: true
					}
				
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedTask(state, action.payload.data.id, (task) => {
							return {
								currentSavingOps: (task.currentSavingOps || 0) + 1
							}
						})
				
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_END:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: false
					}
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedTask(state, action.payload.data.id, (task) => {
							return {
								currentSavingOps: (task.currentSavingOps || 0) - 1
							}
						})
					
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_ERROR:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					//alert("Something went wrong while fetching. "+ action.error)
					return state
				
				case ADD_REQUESTED:
					// Remove again and clean up individual tasks
					return state
				
				case DELETE_REQUESTED:
					// Add project back, including all tasks
					return state
				
				default:
					return state
			}

		
		case GET_ALL:
			return {
				...state,
				count: action.payload.tasks.length,
				tasks: action.payload.tasks
			}
	
		  
	    case ADD:
			return {
				...state,
				count: state.count + 1,
				tasks: [...state.tasks, action.task],
			}
	
	    case DELETE:
			return {
				...state,
				tasks: [
					...state.tasks.filter(t => t.id !== action.id)
				],
				isDeleting: !state.isDeleting
			}
	
	    case DELETE_MULTIPLE:
			return {
				...state,
				tasks: [
					...state.tasks.filter(t => action.ids.indexOf(t.id) < 0)
				],
				isDeleting: !state.isDeleting
			}
		
		case DELETE_BY_RESOURCE:
			return {
				...state,
				tasks: [
					...state.tasks.filter(t => t.resource !== action.resourceid)
				], 
				isDeleting: !state.isDeleting
			}
	
	
	
	
	    case SET_START:
			return stateWithUpdatedTask(state, action.id, (task) => {
				return {
					startweek: action.startweek,
					startyear: action.startyear,
				}
			})
	
	    case OFFSET_START:
			return stateWithUpdatedTask(state, action.id, (task) => {
				const newWeek = calcWeekObjForWeekDuration(task.startweek, task.startyear, action.offsetweeks)
				
				return {
					startweek: newWeek.number,
					startyear: newWeek.year,
				}
			})
	
	    case SET_DURATION:
			return stateWithUpdatedTask(state, action.id, (task) => {
				return {
					duration: action.duration
				}
			})
	
	
	    default:
			return state
	}
}




function updateOrCleanTask(id) {
	return (dispatch, getState) => {
		const state = getState()
		const task = state.tasks.tasks.find(t => t.id === id)
		if (task) {
			dispatch(updateTaskAPI(task))
		} else {
			dispatch(removeTasksFromAllProjects([id]))
			dispatch(removeWarnConflictForTask(id))
			const taskgroupWithTask = selectTaskgroupWithTask(state.taskgroups.taskgroups, id)
			dispatch(removeTaskFromTaskgroup(taskgroupWithTask.id, id))
		}
	}
}




export const addTask = (taskid, resourceid, startweek, startyear, duration) => {
	return dispatch => {
		const task = createTask(taskid, resourceid, startweek, startyear, duration)
		
		dispatch({
			type: ADD,
			task: task
		})
		
		dispatch(postTaskAPI(task))
	}
}

export const deleteTask = (id) => {
	return dispatch => {
		dispatch({
			type: DELETE,
			id: id
		})

		dispatch(deleteTaskAPI({
			id: id
		}))
	}
}

export const deleteTasks = (ids) => {
	return dispatch => {
		dispatch({
			type: DELETE_MULTIPLE,
			ids: ids
		})

		dispatch(deleteTasksAPI(ids))
	}
}


export const deleteTasksByResource = (resourceid) => {
	return dispatch => {
		const rid = parseInt( resourceid )
		
		dispatch({
			type: DELETE_BY_RESOURCE,
			resourceid: rid
		})
		
		dispatch(deleteTasksByResourceAPI(rid))
	}
}


export const setTaskStart = (id, startweek, startyear) => {
	return (dispatch, getState) => {
		const task = getState().tasks.tasks.find(t => t.id === id)

		if (task.startweek !== startweek || task.startyear !== startyear ) {
			dispatch({
				type: SET_START,
				id: id,
				startweek: startweek,
				startyear: startyear,
			})
			
			dispatch(updateOrCleanTask(id))
		}
	}
}


export const offsetTaskStart = (id, offsetweeks) => {
	return (dispatch, getState) => {
		dispatch({
			type: OFFSET_START,
			id: id,
			offsetweeks: offsetweeks,
		})
		
		dispatch(updateOrCleanTask(id))
	}
}


export const setTaskDurationTo = (id, duration) => {
	return (dispatch, getState) => {
		const task = getState().tasks.tasks.find(t => t.id === id)
		
		if (task.duration !== duration) {
			dispatch({
				type: SET_DURATION,
				id: id,
				duration: duration
			})
			
			dispatch(updateOrCleanTask(id))
		}
	}
}



export const compareTasks = (task1, task2) => {
		const sameweek = task1.startyear === task2.startyear && task1.startweek === task2.startweek
		return task1.startyear > task2.startyear || (task1.startyear === task2.startyear && task1.startweek > task2.startweek) || (sameweek && task1.duration < task2.duration)
				? 1
				: sameweek && task1.duration === task2.duration
					? 0
					: -1
	}




export const selectTaskIdsByResource = (tasks, resourceid) => {
	const rid = parseInt( resourceid )
	const taskids = tasks
				.filter(task => task.resource === rid)
				.map(task => task.id)
	//console.log("selecting tasksids "+taskids)
	return taskids
}

export const selectTasksWithinWeeks = (statetasks, taskIds, statetaskgroups, filterFirstWeek, filterLastWeek) => {
	const tasks = taskIds === undefined
				? statetasks
				: taskIds.map(taskid => statetasks.find(t => t.id === taskid))
	
	const filteredTasks = tasks.filter(task => {
		if (!task) return false
	
		if (statetaskgroups.findIndex(taskgroup => taskgroup.shelfed && taskgroup.tasks.indexOf(task.id) !== -1) !== -1) return true	// Potentially in a shelf
	
		const firstWeekObj = createWeekObjForWeekAndYear(task.startweek, task.startyear)
		const lastWeekObj = calcWeekObjForWeekDuration(task.startweek, task.startyear, task.duration - 1)
	
		if (compareWeeks(lastWeekObj, filterFirstWeek) === -1
			|| compareWeeks(firstWeekObj, filterLastWeek) === 1)
				return false	// Task is outside of filter weeks range, so hide it
	
		return true
	})
	
	return filteredTasks
}

export const selectTasks = (statetasks, taskIds, stateresources, filter) => {
	let anyTasksOnFilter = !filter || !filter.isResourcesFiltered
	const filterFirstWeek = createWeekObjForWeekId(filter.weeks.first)
	const filterLastWeek = createWeekObjForWeekId(filter.weeks.last)
	const tasks = taskIds === undefined
				? statetasks
				: taskIds.map(taskid => statetasks.find(t => t.id === taskid))

	const filteredTasks = tasks.map(task => {
		if (!task) return undefined

		const firstWeekObj = createWeekObjForWeekAndYear(task.startweek, task.startyear)
		const lastWeekObj = calcWeekObjForWeekDuration(task.startweek, task.startyear, task.duration - 1)

		if (compareWeeks(lastWeekObj, filterFirstWeek) === -1
			|| compareWeeks(firstWeekObj, filterLastWeek) === 1)
				return undefined	// Task is outside of filter weeks range, so hide it 

		anyTasksOnFilter = anyTasksOnFilter	// optimized find Index... no need to search
								|| filter.resources.findIndex(
										r => r.id === task.resource
									) !== -1

		// find conflicts
		const conflictweeks = []
		iterateWeeks(firstWeekObj, lastWeekObj, (wdate) => {
			const weekid = getIdForDate(wdate)
			const week = filter.weeks.weeks.find(w => w.id === weekid)
			
			if (!week) return

			const resourceConflicts = week.warnConflicts.find(wdb => wdb.resourceid === task.resource)

			if (resourceConflicts && (resourceConflicts.taskids.length > 1 || (resourceConflicts.taskids.length === 1 && resourceConflicts.taskids.indexOf(task.id) === -1 ))) {
				conflictweeks.push(weekid)
			}
		})

		const resource = Object.assign({}, stateresources.find(r => r.id === task.resource))
		return Object.assign({}, task, { resource: resource, conflicts: conflictweeks })
	})
	
	return anyTasksOnFilter
				? filteredTasks.filter(t => t !== undefined)
				: []
}

