import React from 'react';
import { connect } from 'react-redux'
import ProjectHeader from '../components/project-header';
import ProjectContent from '../components/project-content';

const ProjectList = ({ showcontent, projects, weeks, filter }) => {
	return !showcontent
		? (
				projects.map((project, index) =>
					<React.Fragment key={project.id}>
						<tr className="spacer"><th className="bg-container"></th></tr>
		
						<ProjectHeader project={project} filter={filter} />
					</React.Fragment>
				)
			)
		: (
				projects.map((project, index) =>
					<React.Fragment key={project.id}>
						<tr className="spacer">
							<td className="bg-container">
								{ weeks.weeks.map((week, index) => {
									return <div key={week.id} className={"empty-bg " + (week.beforenow ? "before-week" : "") + " " + (week.now ? "this-week" : "") }></div>
								} ) }
							</td> 
						</tr>
		
						<ProjectContent project={project} filter={filter} />
					</React.Fragment>
				)
			)
}

const mapStateToProps = ({ weeks }, { showcontent, projects, filter }) => ({
	weeks,
	projects,
	filter,
	showcontent
})

export default connect(mapStateToProps)(ProjectList);
