import React, { useState, useRef } from 'react';
import useLongPress from '../hooks/uselongpress';
import { connect } from 'react-redux';
import { changeTrelloBoardUrl } from '../reducers/projects'
import { changeTaskgroupTrelloBoardUrl, setTaskgroupFocus } from '../reducers/taskgroups'


const TrelloBoardButton = ({ project, taskgroup, dispatch }) => {
	
	const trelloUrl = project !== undefined
					? project.trelloBoardUrl || ""
					: taskgroup.trelloBoardUrl || ""
	
	const dispatchChangeUrl = project !== undefined
					? changeTrelloBoardUrl
					: changeTaskgroupTrelloBoardUrl

	const dispatchId = project !== undefined
					? project.id
					: taskgroup.id
					
	const dispatchFocusMode = project !== undefined
					? undefined
					: setTaskgroupFocus

	// -----

	const MODE_NORMAL = "mode/NORMAL"
	const MODE_EDIT_URL = "mode/EDITURL"
	
	const [getMode, setMode] = useState(MODE_NORMAL)
	const [editUrl, setEditUrl] = useState(trelloUrl)

	const _container = useRef(null)
	const _trello = useRef(null)


	// -----

	const opacity = (project !== undefined && project.currentSavingOps > 0) 
					|| (taskgroup !== undefined && taskgroup.currentSavingOps > 0)
						? .75
						: 1

	// ========================================

	const _input = useRef(null)

	const editMode = (ev) => {
		console.log("Edit mode")
			setMode(MODE_EDIT_URL)
			if (dispatchFocusMode !== undefined) {
				dispatch(dispatchFocusMode(dispatchId, true))
			}
			setTimeout(() => {
				if (_input.current) {
					_input.current.focus()	// Hack to get _input to update with reference
				}
			}, 40)
		}
		
	const gotoBoard = () => {
			console.log("goto board")
			const gotoUrl = trelloUrl.indexOf('://') !== -1
						? trelloUrl
						: trelloUrl.indexOf('/') !== -1
							? "https://trello.com/" + trelloUrl
							: "https://trello.com/b/" + trelloUrl
			window.open(gotoUrl, '_blank')
			//win.focus()
		}
	
	const isDefined = trelloUrl !== undefined && trelloUrl.length > 3
	const isExternal = isDefined && trelloUrl.indexOf('://') !== -1
	const isTrelloUrl = isDefined && trelloUrl.indexOf('trello.com') !== -1
	const isBoardUrl = isDefined && (trelloUrl.indexOf('/') === -1 || trelloUrl.substr(0,2) === "b/" || (isExternal && isTrelloUrl && trelloUrl.indexOf('/b/') !== -1))
	const isCardUrl = isDefined && (trelloUrl.substr(0,2) === "c/" || (isExternal && isTrelloUrl && trelloUrl.indexOf('/c/') !== -1))
	const isValidUrl = isExternal || isBoardUrl || isCardUrl
	
	const typeClassName = !isValidUrl
							? "invalid"
							: isBoardUrl
								? "trelloboard-link"
								: isCardUrl
									? "trellocard-link"
									: isExternal
										? "external-link"
										: ""
	
	
	const clickEvents = useLongPress( editMode, 300, () => {}, gotoBoard, editMode, 300 )
	const clickAlwaysEditEvents = useLongPress( editMode, 300, () => {}, editMode, editMode, 300 )



	if (getMode === MODE_EDIT_URL) {
		const submiturl = (ev) => {
				ev.preventDefault()
				dispatch(dispatchChangeUrl(dispatchId, editUrl))
				if (dispatchFocusMode !== undefined) {
					dispatch(dispatchFocusMode(dispatchId, false))
				}
				setMode(MODE_NORMAL)
			}
		const changeurl = (ev) => {
				setEditUrl(ev.target.value)
			}

		const rect = _container.current.getBoundingClientRect()
		const top = project !== undefined
						? _container.current.style.top
						: 0
		const newtop = top === ""
						? Math.max(112, rect.top - 10) + 'px'
						: top
		return (
			<React.Fragment key={dispatchId}>
				<div ref={_container} 
					className="scroll-overflow-ui-container"
					style={{ top: newtop }}>
						<div className="ui-popover-container taller-ui right-side">
							<form onSubmit={submiturl} className="ok-button nomargin">
								<button className="btn-small" onClick={submiturl}>OK</button>
								<input ref={_input} className=""
									placeholder="Trello Id"
									type="text"
									value={editUrl}
									onChange={changeurl} onBlur={submiturl} />
							</form>
							<div className="fnt-sm txt-grey help">Board "ABC12", kort "c/BC123" eller link "https://"</div>
						</div>
				</div>
				<div className={"trello-container " + typeClassName} style={{ opacity: opacity }}><button className="trelloboard no-ui" onClick={gotoBoard}>Gå til TrelloBoard</button></div>
			</React.Fragment>
		)		
	}

	// ========================================

	if (isValidUrl) {
		
		return (
			<div ref={_container} 
				className={"trello-container " + typeClassName} 
				style={{ opacity: opacity }}>
					<button ref={_trello}
			 			className="trelloboard no-ui"
						{...clickEvents}
						>Gå til TrelloBoard</button>
			</div>
		);
	}

	// ========================================

	return (
		<div ref={_container} 
			className={ "trello-container " + typeClassName} 
			style={{ opacity: opacity }}>
				<button ref={_trello}
		 			className="trelloboard no-ui"
					{...clickAlwaysEditEvents}
					>Tilføj Trello Board</button>
		</div>
	);
	
}

export default connect()(TrelloBoardButton);