import { findIndexOfElWithIntersectingX, getWeeksDom } from '../helpers/weeks'
import { updateTaskgroupsNameOffsetXpxFromViewbox } from '../reducers/taskgroups';
/*
export const UPDATE_VIEWBOX_DEBOUNCE_TIMER = 'touchstate/UPDATE_VIEWBOX_DEBOUNCE'
export const UPDATE_VIEWBOX_CANCEL_DEBOUNCE_TIMER = 'touchstate/UPDATE_VIEWBOX_CANCEL_DEBOUNCE'


const initialState = {
	debounceTimer: -1
}

export default (state = initialState, action) => {
	switch (action.type) {

		case UPDATE_VIEWBOX_DEBOUNCE_TIMER:

			clearTimeout(state.debounceTimer)
			const debounceTimer = setTimeout(action.onDebounceCallback, action.timeoutMs || 0)
			
			return {
				...state,
				debounceTimer: debounceTimer,
			}

		case UPDATE_VIEWBOX_CANCEL_DEBOUNCE_TIMER:

			clearTimeout(state.debounceTimer)
			
			return {
				...state,
				debounceTimer: null,
			}
		
		default:
			return state
	}
}
*/

let viewboxTimer = -1

export const dispatchCancelViewboxRelatedEvents = () => {
	clearTimeout(viewboxTimer)
	/*return (dispatch, getState) => {
		dispatch({
			type: UPDATE_VIEWBOX_CANCEL_DEBOUNCE_TIMER,
		})
	}*/
}

export const dispatchViewboxRelatedEvents = (updateImmediately) => {
	return (dispatch, getState) => {
		//console.log(viewboxTimer)
		clearTimeout(viewboxTimer)
		viewboxTimer = setTimeout(() => {
		/*dispatch({
			type: UPDATE_VIEWBOX_DEBOUNCE_TIMER,
			timeoutMs: updateImmediately ? 0 : 1000,
			onDebounceCallback: () => {*/
				// IMPORTANT!!! TIED TO PRESENTATION SO MIGHT BREAK IF HTML/DESIGN IS CHANGED 
				const _weeks = getWeeksDom()
				
				if (_weeks && _weeks.length > 0) {
	
					// IMPORTANT!!! TIED TO PRESENTATION SO MIGHT BREAK IF HTML/DESIGN IS CHANGED 
					const _headerlogoRect = document.querySelector('#header-logo').getBoundingClientRect()

					const viewboxOffsetLeftX = Math.max(0, _headerlogoRect.right)
					const viewBoxLeftWeekIndex = Math.max(0, Math.min(findIndexOfElWithIntersectingX(_weeks, viewboxOffsetLeftX) || 0, _weeks.length - 1))
					const viewBoxLeftWeekEl = _weeks[viewBoxLeftWeekIndex].getBoundingClientRect()

					const viewBoxRightWeekIndex = Math.max(0, Math.min(findIndexOfElWithIntersectingX(_weeks, window.innerWidth) || 0, _weeks.length - 1))
					const viewBoxRightWeekEl = _weeks[viewBoxRightWeekIndex].getBoundingClientRect()
	
					const pxoffset_start = viewboxOffsetLeftX - viewBoxLeftWeekEl.left
					
					
					// Find projects on screen
					// IMPORTANT!!! TIED TO PRESENTATION SO MIGHT BREAK IF HTML/DESIGN IS CHANGED 
					const projects = getState().projects
					
					const _projects = document.querySelectorAll('#header-projects tr:not(.spacer)')
					const viewboxTop = _headerlogoRect.height
					const viewboxBottom = window.innerHeight
					
					//console.log("====== vbt: "+ viewboxTop + ", vbb: " + viewboxBottom)
					
					let i = 0
					let visibleProjectIds = []
					while(i < _projects.length) {
						const _pRect = _projects[i].getBoundingClientRect()
						const insideViewbox = (_pRect.top > viewboxTop && _pRect.top < viewboxBottom)
												|| (_pRect.top < viewboxTop && _pRect.bottom > viewboxBottom)
												|| (_pRect.bottom > viewboxTop && _pRect.bottom < viewboxBottom)
						
						//console.log(projects.sortedids[i] + "====== visible: "+ insideViewbox + ", t: " + _pRect.top + ", b: " + _pRect.bottom)

						if (insideViewbox)
							visibleProjectIds.push(projects.sortedids[i])
							
						if (visibleProjectIds.length > 0 && !insideViewbox)
							break
					
						i++
					}
					
					//console.log(projects)
					//console.log(visibleProjectIds)
					
					// Find all taskgroups belonging to projects on screen... we'll filter them when we calculate the name offset on the task group reducer 
					const taskgroupsForVisibleProjects = []
					visibleProjectIds.forEach((pId) => {
						const project = projects.projects.find(project => project.id === pId)
						
						if (project.taskGroups && project.taskGroups.length > 0)
							taskgroupsForVisibleProjects.push(...project.taskGroups)
					})
					
					//console.log(taskgroupsForVisibleProjects)
					
					dispatch(updateTaskgroupsNameOffsetXpxFromViewbox(viewBoxLeftWeekEl.left + pxoffset_start, viewBoxRightWeekEl.right + pxoffset_start, taskgroupsForVisibleProjects))
				}
			//}
		}, updateImmediately ? 0 : 1000)
	}
}
