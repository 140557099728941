import { useState, useEffect, useCallback } from 'react'

export default function useDragTask(
		dragStartCallback = () => {}, 
		dragEndCallback = () => {}, 
		dragType = "", 
		clickCallback = () => {}, 
		longPressCallback = () => {}, 
		longPressMs = 300, 
		doubleClickCallback = () => {}, 
		doubleClickMs = 250, 
		watch = {}, 
		allowDragCheck = () => { return true }
	) {	
	const START_LONGPRESS = 'longpress/START_LONGPRESS'
	const STOP = 'longpress/STOP'

	const [isDragging, setIsDragging] = useState(false)
	const [startSingleClickTime, setStartSingleClickTime] = useState(STOP)
	const [startDoubleClickTime, setStartDoubleClickTime] = useState(new Date())
	
	useEffect(() => {
		let longtimerId
		let singletimerId
		
		switch (startSingleClickTime) {
			case START_LONGPRESS:
				longtimerId = setTimeout(() => {
					clearTimeout(singletimerId)
					//console.log("Long press")
					longPressCallback()
					setStartSingleClickTime(STOP)
				}, longPressMs)
				break
			
			case STOP:
				break
			
			default:
				const msSinceLastSingleClick = startSingleClickTime - startDoubleClickTime
				
				if (msSinceLastSingleClick > 16 && msSinceLastSingleClick < doubleClickMs) {
					//console.log("Double click")
					doubleClickCallback()
					setStartSingleClickTime(STOP)
				} else {
					setStartDoubleClickTime(startSingleClickTime)
					singletimerId = setTimeout(() => {
						//console.log("Click")
						clickCallback()
						setStartSingleClickTime(STOP)
					}, doubleClickMs)
				}
				break
		}

		return () => {
			clearTimeout(singletimerId)
			clearTimeout(longtimerId)
		}

	}, [startSingleClickTime])


	const dragStart = useCallback((ev) => {
		ev.stopPropagation()
		
		setStartSingleClickTime(STOP)

		if (!allowDragCheck(ev)) {
			return
		}
		
		ev.dataTransfer.setData("type", dragType)
		ev.dataTransfer.setData(dragType, "type")	// dragOver hack: can not read data values on dragOver -- only the keys
		
		setIsDragging(true)
		dragStartCallback(ev)
	}, [watch])

	const dragEnd = useCallback((ev) => {
		setIsDragging(false)
		dragEndCallback(ev)
	}, [watch])
	
	
	const start = useCallback((ev) => {
		ev.stopPropagation()
		setStartSingleClickTime(START_LONGPRESS)
	}, [])
	
	
	const stop = useCallback(() => {
		setStartSingleClickTime(new Date())
	}, [isDragging, watch])

	const out = useCallback(() => {
		setStartSingleClickTime(STOP)
	}, [isDragging, watch])

	
	return {
		onMouseDown: start,
		onMouseUp: stop,
		onMouseLeave: out,
		onTouchStart: start,
		onTouchEnd: stop,
		onDragStart: dragStart,
		onDragEnd: dragEnd
	}
}
