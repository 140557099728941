import React from 'react'
import { connect } from 'react-redux'
//import { bindActionCreators } from 'redux'
import { PROJECT } from '../hooks/usedroptask'
import ProjectTaskgroupList from '../components/project-taskgroup-list'
import ProjectTask from '../components/project-task'
import WeekDroptarget from '../components/week-droptarget'


const ProjectContent = ({ project, filter, weeks, dispatch }) => {
	const marginAbove = .5
	const marginBelow = .5
	const marginBetween = .25
	const padding = 0
	const lineHeight = 1.75

	const unitheight = project.isCompacted
						? .25
						: lineHeight + padding
	const height = (marginAbove + ((project.maxy + 1) * (unitheight + marginBetween) - marginBetween) + marginBelow) + 'rem'

	const compactable = project.compactable ? "compactable" : ""
	
	const opacity = project.currentSavingOps > 0
					? .5 : 1
	
	const style = project.colorBg
					? { backgroundColor: project.colorBg,
						height: height, opacity: opacity }
					: { height: height, opacity: opacity }


	return (
		<tr key={project.id} className={compactable}>
			<td key={project.id.toString()} 
				style={style}>
					<div className="bg-container" style={{ minHeight: (project.headerHeight || 0) + 'px' }}>{ 
						weeks.weeks.map((week, index) =>
							<WeekDroptarget key={week.id} targettype={PROJECT} project={project} week={week} />
						)
					}<div className="tasks-container">{
						project.tasks.map((task, index) =>
								<ProjectTask key={task.id} project={project} task={task} weeks={weeks} />
							)
					}</div></div>
					
					<ProjectTaskgroupList project={project} />
			</td>
		</tr>
	)
}

const mapStateToProps = ({ weeks }, { project, filter }) => ({
	weeks,
	project,
	filter
})

export default connect(mapStateToProps)(ProjectContent)
