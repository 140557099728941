import { iterateWeekAndDuration, createWeekObjForWeekAndYear, getIdForDate, isWeekInDuration } from '../helpers/weeks'
import { ADD_WARN_CONFLICT_ID, REMOVE_WARN_CONFLICT_ID, ADD_MULTIPLE_WARN_CONFLICTS } from '../reducers/weeks'
import { selectProject, selectTaskIdsAndTaskGroupTaskIds } from '../reducers/projects'


export const createWarnConflict = (resourceid, taskid) => {
		return {
			resourceid: resourceid,
			taskids: Array.isArray(taskid) 
						? taskid 
						: taskid 
							? [ taskid ] 
							: []
		}
	}



const getWeekIds = (startWeekObj, duration) => {
	const weekids = []
	iterateWeekAndDuration(startWeekObj, duration, (wdate) => {
  		weekids.push(getIdForDate(wdate))
	})
	return weekids
}

const getWeekIdsForTask = (task) => {
	return getWeekIds(createWeekObjForWeekAndYear(task.startweek, task.startyear), task.duration)
}






export const addWarnConflictForTask = (taskid) => {
  return (dispatch, getState) => {
	const task = getState().tasks.tasks.find(t => t.id === taskid)

	dispatch({
	  type: ADD_WARN_CONFLICT_ID,
	  weekids: getWeekIdsForTask(task),
	  resourceid: task.resource,
	  taskid: task.id
	})
  }
}


export const removeWarnConflictForTask = (taskid) => {
  return (dispatch, getState) => {
	const task = getState().tasks.tasks.find(t => t.id === taskid)

	dispatch({
	  type: REMOVE_WARN_CONFLICT_ID,
	  weekids: getWeekIdsForTask(task),
	  resourceid: task.resource,
	  taskid: task.id
	})
  }
}


export const addWarnConflictForTasks = (taskids) => {
  return (dispatch, getState) => {
	const tasks = getState().tasks.tasks.filter(t => taskids.indexOf(t.id) !== -1)
	let weeks = {}
	
	tasks.forEach((task) => {
		const weekids = getWeekIdsForTask(task)
		weekids.forEach((weekid) => {
			const warnConflicts = weeks[weekid] || []
			const resourceConflict = warnConflicts.find((warnConflict) => warnConflict.resourceid === task.resource)
			
			if (resourceConflict === undefined) {
				warnConflicts.push({ resourceid: task.resource, taskids: [task.id] })
			} else if (resourceConflict.taskids.indexOf(task.id) === -1) {
				resourceConflict.taskids.push(task.id)
			}
			weeks[weekid] = warnConflicts
		})
	})

	dispatch({
	  type: ADD_MULTIPLE_WARN_CONFLICTS,
	  weekids: Object.keys(weeks),
	  weeks: weeks
	})
  }
}


export const removeWarnConflictForTasks = (taskids) => {
	return (dispatch, getState) => {
		const tasks = getState().tasks.tasks.filter(t => taskids.indexOf(t.id) !== -1)
		
		tasks.forEach((task) => {
			dispatch({
				type: REMOVE_WARN_CONFLICT_ID,
				weekids: getWeekIdsForTask(task),
				resourceid: task.resource,
				taskid: task.id
			})
		})
	}
}



export const addOrRemoveWarnConflictsForProject = (projectid) => {
	return (dispatch, getState) => {
		const state = getState()
		const project = selectProject(state.projects.projects, projectid)
		const taskids = selectTaskIdsAndTaskGroupTaskIds(state.taskgroups.taskgroups, project)
		const tasks = state.tasks.tasks.filter(t => taskids.indexOf(t.id) !== -1)
		
		const type = project.warnConflicts
						? ADD_WARN_CONFLICT_ID
						: REMOVE_WARN_CONFLICT_ID
		
		tasks.forEach((task) => {
			dispatch({
				type: type,
				weekids: getWeekIdsForTask(task),
				resourceid: task.resource,
				taskid: task.id
			})
		})
	}
}








const offsetWarnConflictForTaskHelper = (dispatch, task, offsetToWeek, offsetToYear, newDuration) => {
	const currWeekIds = getWeekIdsForTask(task)
	const newWeekIds = getWeekIds(createWeekObjForWeekAndYear(offsetToWeek, offsetToYear), newDuration !== undefined ? newDuration : task.duration )
	
	const removeWeekIds = currWeekIds.filter(weekid => !newWeekIds.includes(weekid))
	
	if (removeWeekIds.length > 0) {
		dispatch({
			type: REMOVE_WARN_CONFLICT_ID,
			weekids: removeWeekIds,
			resourceid: task.resource,
			taskid: task.id
		})
	}
	
	const addWeekIds = newWeekIds.filter(weekid => !currWeekIds.includes(weekid))
	
	if (addWeekIds.length > 0) {
		dispatch({
			type: ADD_WARN_CONFLICT_ID,
			weekids: addWeekIds,
			resourceid: task.resource,
			taskid: task.id
		})
	}
}


export const offsetWarnConflictForTask = (projectId, taskId, offsetToWeek, offsetToYear, newDuration) => {
	return (dispatch, getState) => {
		const state = getState()
		const project = state.projects.projects.find(p => p.id === projectId)
		const isProjectWarnConflict = project && project.warnConflicts
		
		if (isProjectWarnConflict) {
			const task = state.tasks.tasks.find(t => t.id === taskId)
			
			offsetWarnConflictForTaskHelper(dispatch, task, offsetToWeek, offsetToYear, newDuration)
		}
	}
}

export const offsetWarnConflictForTasks = (projectId, taskIds, offsetToWeek, offsetToYear, newDuration) => {
	return (dispatch, getState) => {
		const state = getState()
		const project = state.projects.projects.find(p => p.id === projectId)
		const isProjectWarnConflict = project && project.warnConflicts
		
		if (isProjectWarnConflict) {
			const tasks = state.tasks.tasks.filter(t => taskIds.indexOf(t.id) !== -1)
			
			tasks.forEach((task) => {
				offsetWarnConflictForTaskHelper(dispatch, task, offsetToWeek, offsetToYear, newDuration)
			})
		}
	}
}


const taskIsOnWarnConflict = (task, week) => {
	if (!task.conflicts || task.conflicts.length === 0) return false
	
	const conflictIndex = week.warnConflicts.findIndex(wc => wc.resourceid === task.resource.id)
	
	if (conflictIndex === -1 || week.warnConflicts[conflictIndex].taskids.length === 0 || (week.warnConflicts[conflictIndex].taskids.length === 1 && week.warnConflicts[conflictIndex].taskids[0] === task.id)) return false
	
	return isWeekInDuration(week, createWeekObjForWeekAndYear(task.startweek, task.startyear), task.duration)
}


export const mergeWeeksWithTaskConflicts = (weeks, tasks, projects) => {
	const mergedWeeks = weeks.weeks.map((week) => {
			if (week.warnConflicts.length === 0)
				return week
			
			let conflictTask
			const conflictProjectIndex = projects.findIndex(p => {
												const conflictTaskIndex = p.tasks.findIndex(t => taskIsOnWarnConflict(t, week))
												if (conflictTaskIndex !== -1) {
													conflictTask = Object.assign({}, p.tasks[conflictTaskIndex])
													return true
												}
												const conflictTaskgroupIndex = p.taskgroups.findIndex(tg => {
																		const conflictTgTaskIndex = tg.tasks.findIndex(t => taskIsOnWarnConflict(t, week))
																		if (conflictTgTaskIndex !== -1) {
																			conflictTask = Object.assign({}, tg.tasks[conflictTgTaskIndex])
																		}
																		return conflictTgTaskIndex !== -1
																	})
												return conflictTaskgroupIndex !== -1
											})
			
			
		/*
			const conflictIndex = tasks.findIndex(t => {
				const rindex = week.warnConflicts.findIndex(wc => wc.resourceid === t.resource)
				if (rindex === -1 || week.warnConflicts[rindex].taskids.length === 0) return false
				
				return (
						week.warnConflicts[rindex].taskids.length > 1 
						|| (week.warnConflicts[rindex].taskids.length === 1 && week.warnConflicts[rindex].taskids[0] !== t.id)
					) && isWeekInDuration(week, createWeekObjForWeekAndYear(t.startweek, t.startyear), t.duration)
			})
												
			if (conflictIndex === -1) 
				return week
			
			const conflictTaskId = tasks[conflictIndex].id
			
			let conflictProject
			for (let pi=0; pi<projects.length; pi++) {
				if (projects[pi].tasks.findIndex(t => t.id === conflictTaskId) !== -1) {
					conflictProject = projects[pi]
					break
				}
				
				for (let tgi=0; tgi<projects[pi].taskgroups.length; tgi++) {
					if (projects[pi].taskgroups[tgi].tasks.findIndex(t => t.id === conflictTaskId) !== -1) {
						conflictProject = projects[pi]
						break
					}
				}
			}
		*/	
			return conflictProjectIndex !== -1
				? Object.assign({}, week, {
						projectsWithConflicts: [{
							name: projects[conflictProjectIndex].name,
							id: projects[conflictProjectIndex].id,
							task: conflictTask
						}]
					})
				: week
		})
	return Object.assign({}, weeks, { weeks: mergedWeeks })
}