import React, { useRef, useState } from 'react';
import useDragTask from '../hooks/usedragtask';
import { PROJECT } from '../hooks/usedroptask';
import { connect } from 'react-redux';
import { toggleCompactMode, renameProject, toggleWarnConflicts /*, selectTaskIdsAndTaskGroupIds */ } from '../reducers/projects'
import { /* addWarnConflictForTasks, removeWarnConflictForTasks,*/ addOrRemoveWarnConflictsForProject } from '../helpers/warnConflict'


const ProjectHeadline = ({ project, touchstate, dispatch }) => {
	//console.log("render headline")


	const MODE_NORMAL = "mode/NORMAL"
	const MODE_EDIT_NAME = "mode/EDITNAME"
	const MODE_SETTINGS = "mode/SETTINGS"
	
	const [getMode, setMode] = useState(MODE_NORMAL)
	const [editName, setEditName] = useState(project.name)

	const _el = useRef(null)

	const startdrag = (ev) => {
			if (!touchstate.isEnabled)
				return;
				
			console.log("drag project " + project.name)
			
			ev.dataTransfer.setData("text/plain", "Project " + project.name)
			ev.dataTransfer.setData("id", project.id)
		}




	const longclick = (ev) => {
			setMode(MODE_SETTINGS)
		}

	const closeSettings = (ev) => {
			setMode(MODE_NORMAL)
		}

	const [warnConflictsState, setWarnConflictsState] = React.useState(project.warnConflicts)

	const onChangeToggleWarnConflicts = (ev) => {
			dispatch(toggleWarnConflicts(project.id))

			dispatch(addOrRemoveWarnConflictsForProject(project.id))
			
			setWarnConflictsState(!warnConflictsState)
		}



	const singleclick = (ev) => {
			dispatch(toggleCompactMode(project.id))
		}

	const compactedClass = project.isCompacted
				? "btn-compact is-compacted"
				: "btn-compact"


	const _input = useRef(null)

	const doubleclick = (ev) => {
			setMode(MODE_EDIT_NAME)
			setTimeout(() => {
				_input.current.focus()	// Hack to get _input to update with reference
			}, 40)
		}
	const submitname = (ev) => {
			ev.preventDefault()
			if (!editName.trim()) {
				return
			}
			dispatch(renameProject(project.id, editName))
			setMode(MODE_NORMAL)
		}
	const changename = (ev) => {
			setEditName(ev.target.value)
		}

	
	
	const dragtask = useDragTask( startdrag, undefined, PROJECT, project.compactable ? singleclick : undefined, longclick, 500, doubleclick)



	if (!touchstate.isEnabled) {
		return (
			<button ref={_el}
					onClick={singleclick} 
					className={compactedClass}><h3
						className="project"
						>{project.name}</h3></button>
		)
	}


	if (getMode === MODE_EDIT_NAME) {
		return (
			<form onSubmit={submitname}>
				<input ref={_input} className="project margin-half-top"
					placeholder="Indtast projektnavn"
					type="text"
					value={editName}
					onChange={changename} onBlur={submitname} />
			</form>
		)		
	}


	if (getMode === MODE_SETTINGS) {
		const rect = _el.current.getBoundingClientRect()
		const top = project !== undefined
						? _el.current.style.top
						: 0
		const newtop = top === ""
						? Math.max(112, rect.top - 10) + 'px'
						: top

		return (
			<React.Fragment key={project.id}>
				<div ref={_el} className="scroll-overflow-ui-container"
					style={{ top: newtop + 'px' }}>
					<div className="ui-popover-container right-side auto-tall">
						<input type="checkbox" className="slider ui-input" checked={warnConflictsState} onChange={onChangeToggleWarnConflicts} />
						<div>Advar om konflikter</div>
						<br /><br />
						<button className="btn-small" onClick={closeSettings}>OK</button>
					</div>
					<div className="project invisible">{project.name}</div>
				</div>{ project.compactable
					? <button ref={_el} className={compactedClass} onClick={singleclick}>
							<h3 className="project">{project.name}</h3></button>
					: <div className={compactedClass}>
							<h3 className="project">{project.name}</h3>
						</div>
				}
			</React.Fragment>
		)		
	}


	if (project.compactable && project.deletable) {
		return (
			<button ref={_el} className={compactedClass}>
				<h3 draggable {...dragtask} className="project">{project.name}</h3></button>
		)
	}
	
	if (project.compactable) {
		return (
			<button ref={_el} className={compactedClass} onClick={singleclick}>
				<h3 className="project">{project.name}</h3></button>
		)
	}

	return (
		<h3 ref={_el} className="project" draggable {...dragtask}>{project.name}</h3>
	)
}


const mapStateToProps = ({ touchstate }, { project }) => ({
	project: project,
	touchstate: touchstate,
})

export default connect(mapStateToProps)(ProjectHeadline)