import React, { useRef } from 'react';
import { connect } from 'react-redux';
import useMouseKeyTimeout from '../hooks/usemousekeytimeout'
import { dispatchViewboxRelatedEvents } from '../reducers/viewbox';
import { isAndroid } from "../helpers/useragent"
import { getThisWeekDom, getBoardScrollDomEl } from "../helpers/weeks"
import { GetProjectHeaderDomId } from "../components/project-header"


const scrollTo = (scrollContainer, toTop, toLeft) => {
	scrollContainer.scroll({
		top: toTop,
		left: toLeft,
		behavior: 'smooth'
	})
	setTimeout(() => {
		dispatchViewboxRelatedEvents()
	}, 800)
}





const monthAbrv = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']

const WeekNumbers = ({ weeks, dispatch }) => {

    const _container = useRef(null)
    
	const scrollToThisWeek = () => {
			console.log("scroll to this week")
			
			const _thisweek = getThisWeekDom( _container.current )
			if (_thisweek) {
				const thisweek = _thisweek.getBoundingClientRect()
				const projectlist = document.querySelector('thead th:first-child').getBoundingClientRect()

				const container = getBoardScrollDomEl()
				
				if (!container) throw Error("No scroll container")
				
				const currx = isAndroid ? container.scrollLeft : container.scrollX
				const curry = isAndroid ? container.scrollTop : container.scrollY
				const leftpadding = 60

				scrollTo(container, curry, currx + (thisweek.x - ((projectlist.width || 0) + leftpadding)))
			}
		} 

	useMouseKeyTimeout( scrollToThisWeek, undefined, 10 * 60 )


	const monthyear = (week, weeksArr, index) => {
		if (!week.now && (index === 0 || weeks.weeks[index-1].month === week.month)) return
		
		return monthAbrv[week.month] + ' ' + week.year
	}



	return (
		<div ref={_container} 
			onClick={ scrollToThisWeek } onTouchStart={ scrollToThisWeek } 
			className="bg-container" 
			style={{ marginLeft: "-1px" }} 
			title="Click to return to current week">{
				weeks.weeks.map((week, index) => {
					const thereIsAConflict = week.projectsWithConflicts.length > 0
					
					if (thereIsAConflict) {
							
						const scrollToConflictProject = (ev) => {
							ev.preventDefault()
							ev.stopPropagation()
							
							console.log("scroll to conflict")
							
							const _thisweek = getThisWeekDom( _container.current )
							const container = getBoardScrollDomEl()
							
							if (!_thisweek || !container) return
							
							const topPadding = Math.floor(window.innerHeight / 5) + _thisweek.getBoundingClientRect().height
							const projectHeader = document.querySelector('#' + GetProjectHeaderDomId(week.projectsWithConflicts[0].id)).getBoundingClientRect()
								
							const currx = isAndroid ? container.scrollLeft : container.scrollX
							
							scrollTo(container, Math.max(0, projectHeader.top - topPadding), currx)
						}

						return <div key={week.id} onClick={ scrollToConflictProject } className={"week-bg " + (week.beforenow ? "before-week" : "") + " " + (week.now ? "this-week" : "") + " conflict-warning" }>{week.number}<div className="week-newmonth">{ monthyear(week, weeks.weeks, index) }</div></div>
					}
					
					return <div key={week.id} className={"week-bg " + (week.beforenow ? "before-week" : "") + " " + (week.now ? "this-week" : "") }>{week.number}<div className="week-newmonth">{ monthyear(week, weeks.weeks, index) }</div></div>

				} )
		}</div>
	)

}


export default connect()(WeekNumbers)
