import { isAndroid } from "../helpers/useragent"





const getYearFromId = (id) => {
		return parseInt( id.substr(0, 4) )
	}
const getWeekFromId = (id) => {
		return parseInt( id.substr(5, 2) )
	}
export const getIdForWeekAndYear = (w, y) => {
		return y + '-' + ('0' + w).slice(-2)
	}
export const getIdForWeekObj = (wo) => {
		return getIdForWeekAndYear(wo.number, wo.year)
	}

export const getIdForDate = (d) => {
		const dt = new Date(d)
		return getIdForWeekAndYear(getISOWeekOfDate(dt), getISOWeekYearOfDate(dt))
	}




export const getISOWeekOfDate = (date) => {
		const dt = new Date(date)
		let d = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()))
		const dayNum = d.getUTCDay() || 7
		d.setUTCDate(d.getUTCDate() + 4 - dayNum)
		var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1))
		return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
	}

export const getISOWeekMonthOfDate = (date) => {
		const dt = new Date(date)
		return dt.getMonth() + 1
	}

export const getISOWeekYearOfDate = (date) => {
		const dt = new Date(date)
		let d = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()))
		d.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7)
		return d.getFullYear()
	}




export const getDateOfISOWeek = (w, y) => {
    const simple = new Date(y, 0, 1 + (w - 1) * 7)
    const dow = simple.getDay()
    let ISOweekStart = simple
	
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1)
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay())
		
    return ISOweekStart
}




const createWeekObjForDate = (d) => {
		return {
			id: getIdForDate(d),
			number: getISOWeekOfDate(d),
			month: getISOWeekMonthOfDate(d),
			year: getISOWeekYearOfDate(d),
			warnConflicts: [],
			projectsWithConflicts: []
		}
	}
export const createWeekObjForWeekId = (id) => {
		return {
			id: id,
			number: getWeekFromId(id),
			month: getDateOfISOWeek(getWeekFromId(id), getYearFromId(id)).getMonth() + 1,
			year: getYearFromId(id),
			warnConflicts: [],
			projectsWithConflicts: []
		}
	}
export const createWeekObjForWeekAndYear = (w, y) => {
		return {
			id: getIdForWeekAndYear(w, y),
			number: w,
			month: getDateOfISOWeek(w, y).getMonth() + 1,
			year: y,
			warnConflicts: [],
			projectsWithConflicts: []
		}
	}






export const convertUnknownWeekTypeToWeek = (unknownweektype) => {
		return typeof unknownweektype === 'string'
				? createWeekObjForWeekId(unknownweektype)
				: typeof unknownweektype === 'number'
					? createWeekObjForDate(new Date(unknownweektype))
					: typeof unknownweektype.getMonth === 'function'
						? createWeekObjForDate(unknownweektype)
						: unknownweektype
	}





export const iterateWeeks = (first, last, callback) => {
		const firstWeek = convertUnknownWeekTypeToWeek(first)
		const lastWeek = convertUnknownWeekTypeToWeek(last)

		const nextDate = getDateOfISOWeek(firstWeek.number, firstWeek.year)
		const lastDate = getDateOfISOWeek(lastWeek.number, lastWeek.year)

		while (nextDate <= lastDate) {
			callback(nextDate)
			nextDate.setDate(nextDate.getDate() + 7)	// 7 for a week
		}
	}

export const iterateWeekAndDuration = (first, duration, callback) => {
		const firstWeek = convertUnknownWeekTypeToWeek(first)
		const firstWeekDate = getDateOfISOWeek(firstWeek.number, firstWeek.year)
		const last = firstWeekDate.setDate(firstWeekDate.getDate() + (7 * (duration - 1)))

		iterateWeeks(firstWeek, last, callback)
	}






export const createWeekObjArr = (first, last) => {
		const thisweek = createWeekObjForDate(new Date())
		let weekobjs = []
		iterateWeeks(first, last, (date) => {
				const week = createWeekObjForDate(date)
				week.now = thisweek.year === week.year && thisweek.number === week.number
				week.beforenow = thisweek.year > week.year || (thisweek.year === week.year && thisweek.number > week.number)
				weekobjs.push(week);
			})
		return weekobjs
	}





export const compareWeeks = (week1, week2) => {
		return week1.year > week2.year || (week1.year === week2.year && week1.number >= week2.number)
				? 1
				: week1.year === week2.year && week1.number === week2.number
					? 0
					: -1
	}


export const isWeekInBetweenWeeks = (checkWeek, startWeek, endWeek) => {
		const w1 = convertUnknownWeekTypeToWeek(startWeek)
		const compW1 = compareWeeks(checkWeek, w1)
		if (compW1 === 0)
			return true		// same week
		
		if (compW1 === -1)
			return false	// before start week
		
		const w2 = convertUnknownWeekTypeToWeek(endWeek)
		const compW2 = compareWeeks(checkWeek, w2)
		if (compW2 === 0)
			return true		// same week
		
		if (compW2 === 1)
			return false	// after end week
		
		return true			// before end week and after start week
	}

export const isWeekInDuration = (checkWeek, startWeek, duration) => {
		const w1 = convertUnknownWeekTypeToWeek(startWeek)
		const w2 = calcWeekObjForWeekDuration(w1.number, w1.year, duration)
		return isWeekInBetweenWeeks(checkWeek, w1, w2)
	}



export const isDurationIntersectingDuration = (duration1week, duration1duration, duration2week, duration2duration) => {
		const w1start = convertUnknownWeekTypeToWeek(duration1week)
		const w1end = calcWeekObjForWeekDuration(w1start.number, w1start.year, duration1duration)
		
		const w2start = convertUnknownWeekTypeToWeek(duration2week)
		const w2end = calcWeekObjForWeekDuration(w2start.number, w2start.year, duration2duration)

		const compW1end = compareWeeks(w1end, w2start)
		if (compW1end === -1)
			return false	// before start week

		const compW2end = compareWeeks(w2end, w1start)
		if (compW2end === -1)
			return false	// before start week
		
		return true			// they intersect
	}




export const calcWeeksDuration = (week1, week2) => {
		const w1 = convertUnknownWeekTypeToWeek(week1)
		const w2 = convertUnknownWeekTypeToWeek(week2)
		const msPerWeek = 7 * 24 * 60 * 60 * 1000
		
		return Math.round((getDateOfISOWeek(w1.number, w1.year) - getDateOfISOWeek(w2.number, w2.year)) / msPerWeek)
	}

export const calcDateForWeekDuration = (w, y, weekduration) => {
		const taskStartDate = getDateOfISOWeek(w, y)
		const newStartDate = new Date(taskStartDate)
		return newStartDate.setDate(taskStartDate.getDate() + (7 * weekduration))
	}

export const calcWeekObjForWeekDuration = (w, y, weekduration) => {
		const newStartDate = calcDateForWeekDuration(w, y, weekduration)
		return createWeekObjForDate(newStartDate)
	}






export const findIndexOfElWithIntersectingX = (arrEls, x, _searchFromIndex, _searchToIndex) => {
		// Use recursive bubble search. Array should be sorted by x values. Leave _searchFromIndex and _searchToIndex as undefined
		const lastPossibleIndex = arrEls.length - 1
		
		const searchFromIndex = typeof _searchFromIndex === 'number' ? _searchFromIndex : 0
		const searchToIndex = typeof _searchToIndex === 'number' ? _searchToIndex : lastPossibleIndex	
		
		const indexSpan = Math.min(searchToIndex, lastPossibleIndex) - Math.min(searchFromIndex, lastPossibleIndex)	// min to avoid searching outside the array
		if (indexSpan <= 1) {
			const fromElRect = arrEls[searchFromIndex].getBoundingClientRect()
			if (fromElRect.left <= x && fromElRect.right >= x)
				return searchFromIndex
	
			const toElRect = arrEls[searchToIndex].getBoundingClientRect()
			if (toElRect.left <= x && toElRect.right >= x)
				return searchToIndex
	
			return undefined	// We can't search in half indexes
		}
		
		const nextIndex = searchFromIndex + Math.ceil(indexSpan / 2)
		const elRect = arrEls[nextIndex].getBoundingClientRect()
		
		if (elRect.left <= x && elRect.right >= x)
			return nextIndex
		
		if (elRect.left > x)
			return findIndexOfElWithIntersectingX(arrEls, x, searchFromIndex, nextIndex)
	
		return findIndexOfElWithIntersectingX(arrEls, x, nextIndex, searchToIndex)
	}




export const getWeekDomWidth = (optWeekEl) => {
	const weekEl = optWeekEl || getThisWeekDom()
	return weekEl
			? weekEl.clientWidth
			: 96
}

export const getWeeksDom = (optContainer) => {
	return (optContainer || document).querySelectorAll("#header-weeks .bg-container > div")
}

export const getThisWeekDom = (optContainer) => {
	return (optContainer || document).querySelector("#header-weeks .bg-container > .this-week")
}

export const getBoardScroll = (optAndroidScrollEl) => {
	const scrollEl = getBoardScrollDomEl(optAndroidScrollEl)
	return {
		scrollX: scrollEl.scrollLeft !== undefined ? scrollEl.scrollLeft : scrollEl.scrollX,
		scrollY: scrollEl.scrollTop !== undefined ? scrollEl.scrollTop : scrollEl.scrollY
	}
}

export const getBoardScrollDomEl = (optAndroidScrollEl) => {
	return isAndroid
				? (optAndroidScrollEl ? optAndroidScrollEl.current : null) || document.querySelector("#content")
				: window
}
