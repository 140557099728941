export const isAndroid = navigator.appVersion.toLowerCase().indexOf('android') !== -1

export const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)

export const isIOS = [
	'iPad Simulator',
	'iPhone Simulator',
	'iPod Simulator',
	'iPad',
	'iPhone',
	'iPod'
  ].includes(navigator.platform)
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document) // iPad on iOS 13 detection

export const isTouchDevice = ('ontouchstart' in window)
						|| (navigator.maxTouchPoints > 0) 
						|| (navigator.msMaxTouchPoints > 0)