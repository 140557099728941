import axios from "axios";
import { accessDenied, apiError, apiStart, apiEnd, API } from "../actions/api";

export const METHOD_PUT = "PUT"
export const METHOD_GET = "GET"
export const METHOD_POST = "POST"
export const METHOD_DELETE = "DELETE"



const apiMiddleware = ({ dispatch }) => next => action => {
	if (action === undefined) return
	
	next(action)
	
	if (action.type !== API) return
	
	const {
		url,
		method,
		data,
		accessToken,
		onSuccess,
		onFailure,
		label,
		headers
	} = action.payload
	
	const dataOrParams = [METHOD_GET, METHOD_DELETE].includes(method)
							? "params" 
							: "data"

	// axios default configs
	axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || ""
	axios.defaults.headers.common["Content-Type"]="application/json"
	axios.defaults.headers.common["Authorization"] = `Bearer${accessToken}`

	//axios.defaults.headers.common["Access-Control-Allow-Origin"]="*"

	if (label) {
		dispatch(apiStart(label, data))
	}
	
	
	const axiosParams = {
			url,
			method,
			headers,
			[dataOrParams]: data
		}
	
	axios
		.request(axiosParams)
		.then(({ data }) => {
			dispatch(onSuccess(data))
		})
		.catch(error => {
			dispatch(apiError(error, label, data))
			dispatch(onFailure(error))
			
			if (error.response && error.response.status === 403) {
				dispatch(accessDenied(window.location.pathname))
			}
		})
		.finally(() => {
			if (label) {
				dispatch(apiEnd(label, data))
			}
		})
}

export default apiMiddleware