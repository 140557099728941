import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { setTaskgroupPriorityTo } from '../reducers/taskgroups'


const PriorityIndicator = ({ editmode, taskgroup, dispatch }) => {
	
	const PRIORITY_MODE_NORMAL = "priority/mode/NORMAL"
	const PRIORITY_MODE_EDIT = "priority/mode/EDIT"
	const [getMode, setMode] = useState(PRIORITY_MODE_NORMAL)
	
	const _container = useRef(null)

	// -----

	const opacity = taskgroup.currentSavingOps > 0
						? .75
						: 1

	const priority = taskgroup.priority !== undefined
						? taskgroup.priority
						: 0;

	// ========================================

	const toggleMode = (ev) => {
		setMode(getMode === PRIORITY_MODE_NORMAL ? PRIORITY_MODE_EDIT : PRIORITY_MODE_NORMAL)
	}

	const submitPriority = (ev) => {
			ev.preventDefault()
			const newpriority = parseInt(ev.target.value);
			dispatch(setTaskgroupPriorityTo(taskgroup.id, newpriority))
			setMode(PRIORITY_MODE_NORMAL)
	}

	if (getMode === PRIORITY_MODE_EDIT) {
		return (
			<div ref={_container} 
				style={{ opacity: opacity }}
				className="priority-edit">
					<div className={ "priority priority-" + priority } onClick={toggleMode}></div>
						<div className="ui-popover-container right-side auto-tall">
							<form className="nomargin">
								<label className="priority priority--2"><input type="radio" name="priority" value="-2" onClick={submitPriority} />5</label>
								<label className="priority priority--1"><input type="radio" name="priority" value="-1" onClick={submitPriority} />4</label>
								<label className="priority priority-0"><input type="radio" name="priority" value="0" onClick={submitPriority} />3</label>
								<label className="priority priority-1"><input type="radio" name="priority" value="1" onClick={submitPriority} />2</label>
								<label className="priority priority-2"><input type="radio" name="priority" value="2" onClick={submitPriority} />1</label>
							</form>
						</div>
					</div>
		)		
	}

	// ========================================

	return (
		<div ref={_container} 
			style={{ opacity: opacity }}
			className={ "priority priority-" + priority }
			onClick={toggleMode}></div>
	);
	
}

export default connect()(PriorityIndicator);