import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
//import { bindActionCreators } from 'redux'
import useDropTask, { TAG, TASKGROUP, PROJECT } from '../hooks/usedroptask'
import { moveProjectRelativeTo, assignTagToProject, removeTagFromProject, removeTaskgroupFromProject, shelfTaskgroupToProject, unshelfTaskgroupFromProject, setHeaderHeight } from '../reducers/projects'
import ProjectHeadline from '../components/project-headline';
import ProjectTaskgroupShelf from '../components/project-taskgroup-shelf';
import ProjectTagList from '../components/project-tag-list'
import TrelloBoardButton from '../components/trelloboard-button';


export const GetProjectHeaderDomId = (projectid) => {
	return "project-row-" + projectid
}


const GetDragType = (dataTransfer) => {
	const allowedTypes = [TAG.toLowerCase(), TASKGROUP.toLowerCase()]
	const types = dataTransfer.types
	let type = ''
	for (let i = 0; i < types.length; i++) {
		if (allowedTypes.indexOf(types[i].toLowerCase()) >= 0) {
			type = types[i]
			break
		}
	}
	return type
}

const Project = ({ project, filter, weeks, dispatch }) => {
	const _headerarea = useRef(null)
	const _droparea = useRef(null)
	
	useEffect(() => {
		const cont = window.getComputedStyle(_droparea.current)
		const minH = _headerarea.current.clientHeight + parseFloat(cont.getPropertyValue('padding-top')) + parseFloat(cont.getPropertyValue('padding-bottom'))
		if (minH !== project.headerHeight)
			dispatch(setHeaderHeight(project.id, minH))
	}, [ project.name, project.tags, project.taskgroupshelf ]);





	const onDropProject = (ev) => {
			document.body.classList.remove('drag-task')
			document.body.classList.remove('drag-taskgroup')

			const type = ev.dataTransfer.getData("type")
			const id = ev.dataTransfer.getData("id")
			
			const typeParts = type.split("/")
			const hoverclass = "dragover-" + typeParts[typeParts.length-1].toLowerCase()
			if (_droparea.current.classList.contains(hoverclass))
				_droparea.current.classList.remove(hoverclass)

			switch (type) {
				case PROJECT:
					const dropid = parseInt( id )
					if (dropid === project.id || _droparea.current === undefined || !project.deletable)
						return
					
					const rect = ev.target.getBoundingClientRect()
					const insidey = ev.clientY - rect.top
					const height = rect.bottom - rect.top
					const pct = insidey / height
					const shouldmovebefore = pct < .5

					dispatch(moveProjectRelativeTo(dropid, project.id, shouldmovebefore))
					break
				
				case TAG:
					const oldprojectid = parseInt( ev.dataTransfer.getData("prjid") || "-1" )
					
					if (oldprojectid === project.id)
						return

					if (!project.deletable)
						return

					if ((project.tags || []).find(t => t.id === id) !== undefined)
						return

					if (oldprojectid !== -1) {
						dispatch(removeTagFromProject(oldprojectid, id))
					}
					
					dispatch(assignTagToProject(project.id, id))
					break
				
				case TASKGROUP:
					const oldtgprojectid = parseInt( ev.dataTransfer.getData("prjid") || "-1" )
					const oldtgonshelf = ev.dataTransfer.getData("shelf") || false
					
					if (oldtgprojectid === project.id && oldtgonshelf)
						return
				
					if (!project.deletable)
						return
				
					if (oldtgprojectid !== -1) {
						if (oldtgonshelf) {
							dispatch(unshelfTaskgroupFromProject(oldtgprojectid, id))
						} else {
							dispatch(removeTaskgroupFromProject(oldtgprojectid, id))
						}
					}
					
					dispatch(shelfTaskgroupToProject(project.id, id))
					break
								
				default: break
			}
		}

	const onDragOverProject = (ev) => {
		if (!project.deletable)
			return

		const type = GetDragType(ev.dataTransfer)

		if (type === '')
			return

		const typeParts = type.split("/")
		const hoverclass = "dragover-" + typeParts[typeParts.length-1]
		if (!_droparea.current.classList.contains(hoverclass))
			_droparea.current.className += " " + hoverclass
	}


	const onDragLeaveProject = (ev) => {
		if (!project.deletable)
			return
		
		const type = GetDragType(ev.dataTransfer)
		
		if (type === '')
			return
		
		const typeParts = type.split("/")
		const hoverclass = "dragover-" + typeParts[typeParts.length-1]
		if (_droparea.current.classList.contains(hoverclass))
			_droparea.current.classList.remove(hoverclass)
	}


	const dropproject = useDropTask(onDragOverProject, onDragLeaveProject, onDropProject, [PROJECT, TAG, TASKGROUP], undefined)
	
	
	
	
	const marginAbove = .5
	const marginBelow = .5
	const marginBetween = .25
	const padding = 0
	const lineHeight = 1.75

	const unitheight = project.isCompacted
						? .25
						: lineHeight + padding
	const height = (marginAbove + ((project.maxy + 1) * (unitheight + marginBetween) - marginBetween) + marginBelow) + 'rem'

	const compactable = project.compactable ? "compactable" : ""
	const projectDomId = GetProjectHeaderDomId(project.id)
	
	const opacity = project.currentSavingOps > 0
					? .5 : 1
	
	const style = project.colorBg
					? { backgroundColor: project.colorBg,
						height: height, opacity: opacity }
					: { height: height, opacity: opacity }


	return (
		<tr key={project.id} className={compactable} id={projectDomId}>
			<th style={style} className="header-dropzone" ref={_droparea} {...dropproject}>
				<div ref={_headerarea}>
					<ProjectHeadline project={project} />
					<ProjectTagList project={project} tags={project.tags} filter={filter} />
					{ project.deletable &&
						<TrelloBoardButton project={project} />
					}
					<ProjectTaskgroupShelf project={project} />
				</div>
			</th>
		</tr>
	)
}

const mapStateToProps = ({ weeks }, { project, filter }) => ({
	weeks,
	project,
	filter
})

export default connect(mapStateToProps)(Project)
