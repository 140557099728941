import { API_START, API_END, API_ERROR } from '../actions/api';
import { postTagAPI, deleteTagAPI, deleteTagsAPI, updateTagAPI } from '../actions/api-tags';
import { selectColor } from '../helpers/colors';

export const GET_ALL_REQUESTED = 'tag/GET_ALL_REQUESTED'
export const GET_ALL = 'tag/GET_ALL'

export const ADD_REQUESTED = 'tag/ADD_REQUESTED'
export const ADD = 'tag/ADD'

export const DELETE_REQUESTED = 'tag/DELETE_REQUESTED'

export const DELETE = 'tag/DELETE'
export const DELETE_MULTIPLE = 'tag/DELETE_MULTIPLE'

export const UPDATE_REQUESTED = 'tag/UPDATE_REQUESTED'


export const RENAME = 'tag/RENAME'

export const SET_COLOR_BG = 'tag/SET_COLOR_BG'
export const TOGGLE_SELECTED_STATE = 'tag/TOGGLE_SELECTED_STATE'


export const TOGGLE_EDIT_STATE = 'tag/TOGGLE_EDIT_STATE'
export const CLEAR_ALL_SELECTED_STATE = 'tag/CLEAR_ALL_SELECTED_STATE'

export const createTag = (id, label, colorBg, colorTxt) => {
		return {
				id: id,
				label: label,
				colorBg: colorBg,
				colorTxt: colorTxt,
				selected: true,
				currentSavingOps: 0
			}
	}



const initialState = {
	latestid: 0,
	tags: [],
	isDeleting: false,
	isLoading: false,
	showingTagsMenu: false
}



function stateWithUpdatedTag(state, id, updateCallback) {
	return  {
			...state,
			tags: state.tags.map(tag => {
				if (tag.id !== id) {
				    return tag
				}
				
				return Object.assign({}, tag, updateCallback(tag))
			})
		}
}




export default (state = initialState, action) => {
	switch (action.type) {

		case API_START:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: true
					}
				
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedTag(state, action.payload.data.id, (tag) => {
							return {
								currentSavingOps: (tag.currentSavingOps || 0) + 1
							}
						})
				
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_END:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					return {
						...state,
						isLoading: false
					}
				
				case ADD_REQUESTED:
				case UPDATE_REQUESTED:
					return stateWithUpdatedTag(state, action.payload.data.id, (tag) => {
							return {
								currentSavingOps: (tag.currentSavingOps || 0) - 1
							}
						})
					
				case DELETE_REQUESTED:
				default:
					return state
			}
		
		
		case API_ERROR:
			switch (action.payload.type) {
				case GET_ALL_REQUESTED:
					//alert("Something went wrong while fetching. "+ action.error)
					return state
				
				case ADD_REQUESTED:
					// Remove again and clean up individual tasks
					return state
				
				case DELETE_REQUESTED:
					// Add project back, including all tasks
					return state
				
				default:
					return state
			}

		
		case GET_ALL:
			if (!action.payload.tags) {
				return state
			}
			
			const maxid = action.payload.tags.reduce((memo, t) => Math.max(parseInt(t.id), memo), 0)
			const tagsWithSelectedState = action.payload.tags.map(tag => {
							return Object.assign({}, tag, { selected: true })
						})
			return {
				...state,
				latestid: maxid,
				tags: tagsWithSelectedState
			}
	
		  
	    case ADD:
			return {
				...state,
				latestid: state.latestid + 1,
				tags: [...state.tags, action.tag],
			}
	
	    case DELETE:
			return {
				...state,
				tags: [
					...state.tags.filter(t => t.id !== action.id)
				],
				isDeleting: !state.isDeleting
			}
	
	    case DELETE_MULTIPLE:
			return {
				...state,
				tags: [
					...state.tags.filter(t => action.ids.indexOf(t.id) < 0)
				],
				isDeleting: !state.isDeleting
			}
		


		case RENAME:
			const existingWithLabelIndex = state.tags.findIndex(t => t.label === action.label)
			if (existingWithLabelIndex >= 0)
				return state;

			return stateWithUpdatedTag(state, action.id, (tag) => {
					return {
						label: action.label
					}
				})


		case SET_COLOR_BG:
			return stateWithUpdatedTag(state, action.id, (tag) => {
					return {
						colorBg: action.color
					}
				})


		case TOGGLE_SELECTED_STATE:
			// First resource to toggle... deselect all but this
			const firstDeselectedIndex = state.tags.findIndex(t => !t.selected)
			const allSelected = firstDeselectedIndex === -1
			if (allSelected) {
				return {
						...state,
						tags: state.tags.map(tag => {
							return Object.assign({}, tag, { selected: tag.id === action.id })
						})
					}
			}
			
			// Last resource to toggle... select all
			const filtered = selectSelectedTags(state.tags)
			if (filtered.length === 1) {
				const targettag = state.tags.find(t => t.id === action.id)
				if (targettag && targettag.selected) {
					return {
							...state,
							tags: state.tags.map(tag => {
								return Object.assign({}, tag, { selected: true })
							})
						}
				}
			}
			
			// Just another one... add it
			return stateWithUpdatedTag(state, action.id, (tag) => {
					return {
						selected: action.select === undefined 
									? !tag.selected		// toggle
									: tag.select					// force state
					}
				})


		case CLEAR_ALL_SELECTED_STATE:
			return {
				...state,
				tags: state.tags.map(tag => {
					return Object.assign({}, tag, { selected: false })
				})
			}


		case TOGGLE_EDIT_STATE:
			return {
				...state,
				showingTagsMenu: action.show === undefined 
									? !state.showingTagsMenu	// toggle
									: action.show			// force state
			}
	
	
		
	
	    default:
			return state
	}
}






export const addTag = (label, nextcolornumber) => {
	const randomHsl = selectColor(nextcolornumber)
	return (dispatch, getState) => {
		const tags = getState().tags
		
		const index = tags.tags.findIndex(t => t.label === label)
		if (index >= 0) {
			return
		}

		const nextid = tags.latestid + 1
		const tag = createTag(nextid.toString(), label, randomHsl, "#fff")
		
		dispatch({
			type: ADD,
			tag: tag
		})
		
		dispatch(postTagAPI(tag))
	}
}

export const deleteTag = (id) => {
	return dispatch => {
		dispatch({
			type: DELETE,
			id: id
		})

		dispatch(deleteTagAPI({
			id: id
		}))
	}
}

export const deleteTags = (ids) => {
	return dispatch => {
		dispatch({
			type: DELETE_MULTIPLE,
			ids: ids
		})

		dispatch(deleteTagsAPI(ids))
	}
}


export const renameTag = (id, label) => {
	return (dispatch, getState) => {
		dispatch({
			type: RENAME,
			id: id,
			label: label
		})
		
		const tag = getState().tags.tags.find(t => t.id === id)
		dispatch(updateTagAPI(tag))
	}
}

export const setTagColorBg = (id, color) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_COLOR_BG,
			id: id,
			color: color
		})
		
		const tag = getState().tags.tags.find(t => t.id === id)
		dispatch(updateTagAPI(tag))
	}
}

export const changeSelectedState = (id, select) => {
	return (dispatch, getState) => {
		dispatch({
			type: TOGGLE_SELECTED_STATE,
			id: id,
			select: select
		})
	}
}
export const clearTagFilter = () => {
	return (dispatch, getState) => {
		dispatch({
			type: CLEAR_ALL_SELECTED_STATE,
		})
	}
}




export const changeEditState = (showEdit) => {
	return dispatch => {
		dispatch({
			type: TOGGLE_EDIT_STATE,
			show: showEdit
		})
	}
}




export const compareTags = (tag1, tag2) => {
		return ('' + tag1).localeCompare(tag2)
	}


export const selectTags = (statetags, tagIds) => {
	const tags = tagIds.map(tagid => {
		const tag = statetags.find(t => t.id === tagid)
		if (tag === undefined) {
			return undefined
		}
		
		return tag
	})
	const filteredTags = tags.filter(t => t !== undefined)
	
	return filteredTags
}



export const selectSelectedTags = (statetags) => {
	const filteredTags = statetags.filter(t => t !== undefined && t.selected === true)
	return filteredTags
}

